import * as S from "./styles";
import { colors } from "@/constants";
import { useUserContext } from "@/hooks";
import { formatCurrency } from "@/shared";
import { useDbFunctions } from "@/talons";
import { ToolTip } from "@/components";
import { useEffect, useRef, useState } from "react";
import { RiInformation2Line, RiArrowDownSFill, RiArrowUpSFill, RiFundsBoxLine, RiCalendar2Line } from "react-icons/ri";
import Campanhas from "./campanhas";

const MetricasComp = ({ isVisible, indicadores }: any) => {
  const [height, setHeight] = useState(0);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (wrapperRef.current) {
      requestAnimationFrame(() => {
        if (wrapperRef.current) {
          setHeight(isVisible ? wrapperRef.current.scrollHeight : 0);
        }
      });
    }
  }, [isVisible]);

  const getMetricColors = (vendaDia: number, vendaDiaMedia: number) => {
    if (vendaDia <= vendaDiaMedia) {
      return {
        background: colors.alert.green50,
        color: colors.alert.green110
      };
    } else if (vendaDia > vendaDiaMedia) {
      return {
        background: colors.alert.red50,
        color: colors.alert.red200
      }
    }
  };

  const isIntAcronym = (acronym: string | undefined, name: string | undefined, intAcronyms: string[]): boolean => {
    const lowerAcronym = acronym?.toLocaleLowerCase();
    const lowerName = name?.toLocaleLowerCase();
    return (lowerAcronym !== undefined && intAcronyms.includes(lowerAcronym)) || (lowerName !== undefined && intAcronyms.includes(lowerName));
  };

  const faturamentoIndicator = indicadores?.find((indicator: any) => indicator.DESC_REDUZIDA === 'MF' || indicator.DESCRICAO === 'Faturamento Total');

  const valorRealizado = Number(faturamentoIndicator?.VLRITEM) - Number(faturamentoIndicator?.VLRDEVOLITEM);
  const metaFaltante = Number(faturamentoIndicator?.META_NECESSARIA) - valorRealizado;
  const metaAlcancada = valorRealizado > Number(faturamentoIndicator?.META_NECESSARIA);
  const valorPorDia = metaFaltante / Number(faturamentoIndicator?.DIAS_UTEIS_MES_RESTANTES);

  const parsedIndicators = indicadores?.map((indicator: any) => {
    const { DESCRICAO: name,
      DESC_REDUZIDA: acronym,
      PCT_META_ATINGIDO: percentage,
      VLRITEM: itemValue,
      VLR_PREMIO: prizeValue,
      META_NECESSARIA: goalValue,
      DIAS_UTEIS_MES: workDays,
      DIAS_UTEIS_MES_RESTANTES: remainingWorkDays,
      VLRDEVOLITEM: devolutionValue
    } = indicator;

    const valorRealizado = itemValue - devolutionValue;
    const vendaDia = goalValue / workDays;
    const metaAcumulado = (goalValue / workDays) * (workDays - remainingWorkDays);
    const percentMeta = (valorRealizado / metaAcumulado) * 100;

    const vendaDiaAcumulado = vendaDia * (workDays - remainingWorkDays);
    const metaDia = vendaDiaAcumulado - valorRealizado;
    const vendaValor = valorRealizado / (workDays - remainingWorkDays);

    const typeColor = getMetricColors(vendaDia, vendaValor);

    const intAcronyms = ['pc', 'mp', 'pp', 'ic', 'positivação de clientes digital']
    // const fullRowAcronyms = ['mf', 'mp', 'cp', 'pc'];
    //const isFullRow = fullRowAcronyms.includes(acronym.toLocaleLowerCase());
    const isFullRow = true;

    return (
      <S.Indicator key={acronym} row={isFullRow}>
        <S.BoxHeader>
          <S.Title>
            {name?.toLocaleLowerCase()}
          </S.Title>
        </S.BoxHeader>
        <S.BoxContent>
          <S.IndicatorResume>
            <S.Data>
              <S.PercentWrapper>
                <S.Field>Realizado</S.Field>
                <S.Percent color={typeColor}>
                  {valorRealizado >= metaAcumulado ? <RiArrowUpSFill /> : <RiArrowDownSFill />}
                  {parseInt(percentMeta.toString())}%
                </S.Percent>
              </S.PercentWrapper>
              <S.Value>{isIntAcronym(acronym, name, intAcronyms) ? valorRealizado : formatCurrency({ noDecimals: true, value: (valorRealizado) })}</S.Value>
            </S.Data>
            {/* <S.Percentage> {percentMeta.toFixed(2)}% </S.Percentage> */}
            <S.Data>
              <ToolTip text="Meta acumulada = (Meta do mês / total de dias) x dias passados">
                <S.Field>
                  Meta acumulada
                  <RiInformation2Line />
                </S.Field>
              </ToolTip>
              <S.Value>
                {metaAcumulado < 0 ? `Atingido` : isIntAcronym(acronym, name, intAcronyms) ? metaAcumulado?.toFixed(2) : formatCurrency({ noDecimals: true, value: (metaAcumulado) })}
              </S.Value>
            </S.Data>
            <S.Row>
              <S.Data>
                <S.PercentWrapper>
                  <S.Percent color={typeColor}>
                    {Number.isInteger(percentage) ? percentage : percentage?.toFixed(2)}%
                  </S.Percent>
                  <S.Field>Meta mês</S.Field>
                </S.PercentWrapper>
                <S.Value>{isIntAcronym(acronym, name, intAcronyms) ? goalValue : formatCurrency({ noDecimals: true, value: (goalValue) })}</S.Value>
              </S.Data>
            </S.Row>
          </S.IndicatorResume>
        </S.BoxContent>
      </S.Indicator>
    )
  });

  return (
    <S.MetricasWrapper isVisible={isVisible} height={height}>
      {faturamentoIndicator && (
        <>
          <S.ResumeWrapper>
            <S.Resume>
              <S.ResumeTitleWrapper>
                {metaAlcancada ? (
                  <S.ResumeTitle> A sua meta é <b>{formatCurrency({ noDecimals: true, value: (parseInt(faturamentoIndicator?.META_NECESSARIA)) })}</b>, e você atingiu <b>{formatCurrency({ noDecimals: true, value: (valorRealizado) })}</b> </S.ResumeTitle>
                ) : (
                  <>
                    <S.ResumeTitle> Você atingiu {faturamentoIndicator?.PCT_META_ATINGIDO}% da sua meta</S.ResumeTitle>
                    <S.ResumeDetailed> Sua meta é: {formatCurrency({ noDecimals: true, value: (Number(faturamentoIndicator?.META_NECESSARIA)) })} </S.ResumeDetailed>
                  </>
                )}

              </S.ResumeTitleWrapper>
              {!metaAlcancada && (
                <>
                  <S.ResumeDetails>
                    <S.MetaAtingida>
                      <S.ResumeDetailed>Você atingiu</S.ResumeDetailed>
                      {formatCurrency({ value: valorRealizado })}
                    </S.MetaAtingida>
                    <S.MetaFaltante>
                      <S.ResumeDetailed>Faltam</S.ResumeDetailed>
                      <S.CalcMetaFaltante>
                        {formatCurrency({ value: metaFaltante })}
                      </S.CalcMetaFaltante>
                    </S.MetaFaltante>
                  </S.ResumeDetails>
                  {faturamentoIndicator?.DIAS_UTEIS_MES_RESTANTES > 0 && (
                    <S.MetaFaltanteDetails>
                      <S.IconFunds>
                        <RiFundsBoxLine />
                      </S.IconFunds>
                      <span>
                        Se for faturado por dia
                        <small>{formatCurrency({ value: valorPorDia })}</small>
                        , nos próximos {faturamentoIndicator?.DIAS_UTEIS_MES_RESTANTES} dias, você atingirá sua meta!
                      </span>
                    </S.MetaFaltanteDetails>
                  )}
                </>
              )}
              <S.Date>
                <RiCalendar2Line />
                {new Date().toLocaleDateString()}
              </S.Date>
            </S.Resume>
            <S.DaysControl
              actualDays={Number(faturamentoIndicator?.DIAS_UTEIS_MES) - Number(faturamentoIndicator?.DIAS_UTEIS_MES_RESTANTES)}
              remainingDays={Number(faturamentoIndicator?.DIAS_UTEIS_MES_RESTANTES)}
            >
              <S.ActualDay>
                {!metaAlcancada ? (
                  <>
                    {Number(faturamentoIndicator?.DIAS_UTEIS_MES) - Number(faturamentoIndicator?.DIAS_UTEIS_MES_RESTANTES)} {Number(faturamentoIndicator?.DIAS_UTEIS_MES) > 1 ? 'dias' : 'dia'}
                  </>
                ) : 'Meta atingida'}
              </S.ActualDay>
              <S.RemainingDays>
                {!metaAlcancada && (
                  <>
                    {faturamentoIndicator?.DIAS_UTEIS_MES_RESTANTES} {Number(faturamentoIndicator?.DIAS_UTEIS_MES_RESTANTES) > 1 ? 'dias' : 'dia'}
                  </>
                )}

              </S.RemainingDays>
            </S.DaysControl>
          </S.ResumeWrapper>
          <S.DayRow>
            <S.ResumeDetailed>
              Início
            </S.ResumeDetailed>
            <S.ResumeDetailed>
              {faturamentoIndicator?.DIAS_UTEIS_MES} dias
            </S.ResumeDetailed>
          </S.DayRow>
        </>
      )}
      <S.Container ref={wrapperRef}>
        <S.BoxWrapper>
          {parsedIndicators}
        </S.BoxWrapper>
      </S.Container>
      {/* <Campanhas indicadores={indicadores} /> */}
    </S.MetricasWrapper>
  )
};

export default MetricasComp;
