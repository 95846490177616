import { useDbFunctions } from "@/talons";
import { useClient } from "./ClientContext";
import { ClientProps, ProductProps, StockProps } from "@/types";
import { getOnStorage, setOnStorage, removeFromStorage } from "@/shared";

import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  SetStateAction,
  Dispatch,
  RefObject,
} from "react";
import { useDiscountFlex } from "./DiscountFlexContext";
import { useStock } from "./StockContext";

export interface CartItems {
  sku: number;
  uid: string;
  quantity: number;
  option_uid: string;
  category_id: number;
}

export interface CartDetailsProps {
  id?: string;
  total?: number;
  quantity: number;
  subtotal?: number;
  items: CartItems[];
}

export interface CartProps {
  cartId: string;
  cart: CartItems[];
  filterTerm: string;
  isSearching: boolean;
  estoque: StockProps[];
  totalQuantity: number;
  currentClient: ClientProps;
  cartDetails: Record<number, CartDetailsProps>;
  isAdding: boolean;

  checkLimit: (
    uid: string,
    sku: number,
    quantity: number,
    updatedCart: CartItems[],
    showAlert?: boolean
  ) => number;
  addProductToCart: (data: {
    sku: number;
    quantity: number;
    option_uid: string;
    uid: string;
    category_id: number;
  }) => void;
  updateProductAmount: (data: {
    uid: string;
    sku: number;
    quantity: number;
  }) => void;
  clearCart: () => void;
  handleRemoveItems: () => void;
  getLimit: (sku: number) => number;
  getStockMsg: (sku: number) => string | undefined;
  setFilterTerm: Dispatch<SetStateAction<string>>;
  removeProductFromCart: ({ uid }: { uid: string }) => void;
  createEmptyCart: ({ client }: { client: ClientProps }) => void;
  setCurrentClientDetails: ({ client }: { client: ClientProps }) => void;
  setIsSearching: Dispatch<SetStateAction<boolean>>;
  loadedEstoque: RefObject<boolean>;
  setHasLoadedEstoque: Dispatch<SetStateAction<boolean>>;
  setCartLoading: Dispatch<SetStateAction<boolean>>;
  cartLoading: boolean;
}

export const CartContext = createContext({} as CartProps);

const CartProvider = ({ children }: React.PropsWithChildren) => {
  const { getOnDB } = useDbFunctions();
  const { clearClientDiscount } = useDiscountFlex();
  const { stockLeft } = useStock();

  const [hasLoadedEstoque, setHasLoadedEstoque] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  const [catalog, setCatalog] = useState({} as Record<number, ProductProps>);

  const [currentClient, setCurrentClient] = useState(() => {
    const client = getOnStorage("selected_client");
    if (client) return client as ClientProps;
    return {} as ClientProps;
  });
  const [cartId, setCartId] = useState(() => {
    const id = getOnStorage("cart_id");
    if (id) return id;
    return "";
  });

  useEffect(() => {
    const getCatalogo = async () => {
      const catalogo = await getOnDB("dicionario_produtos");
      setCatalog(catalogo);
    };
    getCatalogo();
  }, [currentClient]);

  const loadedEstoque = useRef(false);
  const [isSearching, setIsSearching] = useState(false);
  const [filterTerm, setFilterTerm] = useState("");

  const [estoque, setEstoque] = useState([] as StockProps[]);
  const [dictEstoque, setDictEstoque] = useState(
    [] as { [key: number]: StockProps }
  );

  const [cart, setCart] = useState(() => {
    const saveCurrentCartInLocalStorage = getOnStorage(`cart_details`);
    if (saveCurrentCartInLocalStorage) {
      return (
        saveCurrentCartInLocalStorage?.[currentClient?.COD_CLIENTE]?.items ?? []
      );
    }
    return [] as CartItems[];
  });

  const [cartDetails, setCartDetails] = useState(() => {
    const currentCartDetails = getOnStorage("cart_details");
    if (currentCartDetails) {
      return currentCartDetails;
    }
    return {} as Record<number, CartDetailsProps>;
  });

  const { selectedClient } = useClient();

  const updateCartRef = useRef([] as CartItems[]);
  const currentClientRef = useRef({} as ClientProps);
  const updateCartDetailsRef = useRef({} as Record<number, CartDetailsProps>);

  useEffect(() => {
    updateCartRef.current = cart;
  }, [cart]);

  useEffect(() => {
    updateCartDetailsRef.current = cartDetails;
  }, [cartDetails]);

  const loadEstoque = useCallback(async () => {
    const list = await getOnDB("estoque").then(resp => {
      return resp?.filter(i => i.COD_LOJA === selectedClient.COD_LOJA)
    });
    if (!list?.length) return;

    setDictEstoque(list.reduce((acc, i) => ({ ...acc, [i.COD_PRODUTO]: i }), {}));
    setEstoque(list);
    setHasLoadedEstoque(true);
  }, [getOnDB]);


  useEffect(() => {
    if (selectedClient && (!loadedEstoque.current || !hasLoadedEstoque)) {
      loadEstoque();
      loadedEstoque.current = true;
    }
  }, [selectedClient, loadEstoque, hasLoadedEstoque]);

  useEffect(() => {
    if (getOnStorage('selected_client') === selectedClient) return

    loadEstoque();
  }, [selectedClient]);

  useEffect(() => {
    currentClientRef.current = currentClient;
  }, [currentClient]);

  const cartDetailsPreviousValue = updateCartDetailsRef.current ?? cartDetails;

  const currentClientPreviousValue = currentClientRef.current ?? currentClient;

  const setCurrentClientDetails = useCallback(
    ({ client }: { client: ClientProps }) => {
      setCurrentClient(client);
    },
    []
  );

  const getLimit = useCallback(
    (id: number) => dictEstoque?.[id]?.QTD_ESTOQUE || 0,
    [dictEstoque]
  );

  const getStockMsg = useCallback(
    (id: number) => dictEstoque?.[id]?.MSG_ESTOQUE,
    [dictEstoque]
  );

  const removeProductFromCart = useCallback(
    ({ uid }: { uid: string }) => {
      if (!currentClient || !currentClient?.COD_CLIENTE) {
        return window.alert(`Por favor, selecione um cliente para continuar`);
      }
      const updatedCart = [...cartDetails?.[currentClient?.COD_CLIENTE]?.items];
      const productIndex = updatedCart.find((product) => product.uid === uid);
      if (productIndex) {
        const newCart = updatedCart.filter((product) => product.uid !== uid);
        setCart(newCart);
        const total = newCart?.reduce((acc, i) => (i?.quantity ? acc += i?.quantity : acc), 0);
        setCartDetails({
          ...cartDetails,
          [currentClient?.COD_CLIENTE]: {
            quantity: total,
            items: newCart || [],
          },
        });
      }
    },
    [cartDetails, currentClient]
  );

  const checkLimit = useCallback(
    (
      uid: string,
      sku: number,
      quantity: number,
      updatedCart: CartItems[],
    ): number => {
      const packQtd = Number(uid?.split("-")[2]);
      const initialQtd = quantity * packQtd;

      const amount = updatedCart?.reduce((acc, i) => {
        if (i?.sku !== sku || uid === i?.uid) return acc;

        const [, qtd] = i?.option_uid?.split("_");
        return acc + i?.quantity * Number(qtd);
      }, initialQtd);

      const limit = getLimit(sku);
      const stock = stockLeft?.[sku]?.stock_left || 0;

      const withouStock = stockLeft?.[sku] && stockLeft?.[sku]?.hasOwnProperty("stock_left") ? stock < amount : limit < amount;

      if (withouStock) {
        const newQtd = Math.trunc(stockLeft?.[sku]?.hasOwnProperty("stock_left") ? (stock - (amount - initialQtd)) / packQtd : (limit - (amount - initialQtd)) / packQtd);
        quantity = newQtd;
      }

      const packLimit = Math.floor(limit / packQtd);
      const shouldRemove = !limit || packLimit < quantity;

      console.log({ limit });
      console.log({ amount });
      console.log({ packQtd });
      console.log({ quantity });
      console.log({ stockLeft });
      console.log({ initialQtd });
      console.log({ withouStock });
      console.log({ shouldRemove });
      
      if (shouldRemove) {
        alert(`Produto sem estoque: ${catalog?.[sku]?.DESC_PRODUTO?.toUpperCase()}`);
        removeProductFromCart({ uid });
      }

      return quantity;
    },
    [getLimit, stockLeft]
  );

  useEffect(() => {
    if (cartId) {
      setOnStorage("cart_id", cartId);
    }
  }, [cartId]);

  useEffect(() => {
    if (cartDetailsPreviousValue !== cartDetails) {
      setOnStorage("cart_details", cartDetails);
    }
  }, [cartDetails, cartDetailsPreviousValue]);

  useEffect(() => {
    if (
      selectedClient?.COD_CLIENTE &&
      selectedClient !== currentClientPreviousValue
    ) {
      setCurrentClient(selectedClient);
    }
  }, [currentClientPreviousValue, selectedClient]);

  const quantity = useMemo(() => {
    if (
      cartDetails?.[currentClient?.COD_CLIENTE]?.quantity ||
      cartDetails?.[currentClient?.COD_CLIENTE]?.items?.length
    ) {
      return cartDetails?.[currentClient?.COD_CLIENTE]?.quantity;
    }
    return 0;
  }, [cartDetails, currentClient?.COD_CLIENTE]);

  const saveClientCartDetails = useCallback(
    ({ client }: { client: ClientProps }) => {
      setOnStorage(
        `client_cart`,
        JSON.stringify({ cartId, ...cartDetails }),
        String(client?.COD_CLIENTE)
      );
      removeFromStorage("cart");
      setCart([]);
      setCartDetails({});
    },
    [cartDetails, cartId]
  );

  const createEmptyCart = useCallback(
    ({ client }: { client: ClientProps | undefined }) => {
      if (!client?.COD_CLIENTE || client === undefined) {
        return;
      }
      setCartId(`${client?.COD_CLIENTE}`);
    },
    []
  );

  useEffect(() => {
    if (currentClientRef.current !== currentClient) {
      currentClientRef.current = currentClient;
    }
  }, [currentClient, saveClientCartDetails]);

  const addProductToCart = useCallback(
    ({
      sku,
      quantity,
      option_uid,
      uid,
      category_id,
    }: {
      sku: number;
      quantity: number;
      option_uid: string;
      uid: string;
      category_id: number;
    }) => {
      if (!currentClient || !currentClient?.COD_CLIENTE) {
        return window.alert(`Por favor, selecione um cliente para continuar`);
      }
  
      setIsAdding(true);
  
      // Usar uma função de callback no setCartDetails para garantir o estado atualizado
      setCartDetails((prevCartDetails) => {
        const currentCart =
          prevCartDetails?.[currentClient?.COD_CLIENTE]?.items || [];
  
        const updatedCart = [...currentCart];
  
        quantity = checkLimit(uid, sku, quantity, updatedCart);
  
        if (quantity <= 0) {
          return prevCartDetails;
        }

        const productIsInCart = updatedCart.find(
          (product) => product?.sku === sku && uid === product?.uid
        );
  
        if (productIsInCart) {
          productIsInCart.quantity = quantity; 
        } else {
  
          updatedCart.push({
            uid,
            sku,
            quantity: quantity || 1,
            option_uid,
            category_id,
          });
        }
  
        const total = updatedCart.reduce(
          (acc, i) => (i?.quantity ? acc + i.quantity : acc),
          0
        );
  
        return {
          ...prevCartDetails,
          [currentClient?.COD_CLIENTE]: {
            quantity: total,
            items: updatedCart,
          },
        };
      });
  
      setCart((prevCart) => {
        return prevCart;
      });
  
      setIsAdding(false);
    },
    [currentClient, checkLimit]
  );

  const updateProductAmount = useCallback(
    ({
      uid,
      sku,
      quantity,
    }: {
      uid: string;
      sku: number;
      quantity: number;
    }) => {
      if (!currentClient?.COD_CLIENTE) {
        return window.alert(`Por favor, selecione um cliente para continuar`);
      }

      let updatedCart = [
        ...cartDetails?.[currentClient?.COD_CLIENTE]?.items,
      ] as CartItems[];

      quantity = checkLimit(uid, sku, quantity, updatedCart);

      if (quantity < 0) {
        return;
      }

      const productIndex = updatedCart?.findIndex(
        (el) => el?.sku === sku && uid === el?.uid
      );

      if (quantity === 0) {
        return removeProductFromCart({ uid });
      }

      if (updatedCart[productIndex]) {
        updatedCart[productIndex].quantity = quantity;

        const total = updatedCart?.reduce((acc, i) => (i?.quantity ? acc += i?.quantity : acc), 0);

        setCart(updatedCart);
        setCartDetails({
          ...cartDetails,
          [currentClient?.COD_CLIENTE]: {
            quantity: total,
            items: updatedCart || [],
          },
        });
      }
    },
    [cartDetails, currentClient, checkLimit, removeProductFromCart]
  );

  const clearCart = useCallback(() => {
    setCart([]);
    removeFromStorage("cart");
  }, []);

  const handleRemoveItems = useCallback(() => {
    clearClientDiscount();
    setCartDetails({
      ...cartDetails,
      [currentClient?.COD_CLIENTE]: { items: [], quantity: 0 },
    });
  }, [cartDetails, currentClient?.COD_CLIENTE, clearClientDiscount]);

  const contextValue = useMemo(
    () => ({
      cart,
      cartId,
      estoque,
      cartDetails,
      currentClient,
      totalQuantity: quantity,
      addProductToCart,
      getLimit,
      getStockMsg,
      loadedEstoque,
      removeProductFromCart,
      updateProductAmount,
      createEmptyCart,
      clearCart,
      checkLimit,
      setCurrentClientDetails,
      handleRemoveItems,
      isSearching,
      setIsSearching,
      filterTerm,
      setFilterTerm,
      setHasLoadedEstoque,
      isAdding,
      cartLoading,
      setCartLoading,
    }),
    [
      cart,
      cartId,
      estoque,
      cartDetails,
      currentClient,
      quantity,
      getStockMsg,
      getLimit,
      checkLimit,
      addProductToCart,
      removeProductFromCart,
      updateProductAmount,
      createEmptyCart,
      clearCart,
      setCurrentClientDetails,
      handleRemoveItems,
      isSearching,
      filterTerm,
      isAdding,
      cartLoading,
    ]
  );

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  );
};

const useCart = () => {
  const context = useContext(CartContext);

  if (!context)
    throw new Error(
      "O context useCart só pode ser usado dentro de seu escopo. Erro ao usar hook useCart"
    );

  return context;
};

export { CartProvider, useCart };
