import * as S from './styles';
import { colors } from '@/constants';
import { useCalcPrice } from '@/talons';
import { cleanStr, formatCurrency } from '@/shared';
import { useCallback, useMemo, useState } from 'react';
import { CartItems, useCart } from '@/hooks/CartContext';
import { Accordion, CartProductCard } from '@/components';
import { useCartTotals, useClient, useGlobals } from '@/hooks';
import { CategoryProps, ProductProps, PriceProductProps } from '@/types';

interface CartProductItem {
  item: CartItems;
  category: CategoryProps | undefined;
  product: ProductProps | undefined;
}

interface Product extends ProductProps {
  quantity: number;
  packing: string;
  currentPrice: PriceProductProps;
  sku: number;
  uid: string;
  option_uid: string;
  category_id: number;
}

interface SummaryProps {
  items: CartProductItem[];
  productMap: Record<number, ProductProps>;
  cartTotalQuantity?: number;
  subtotal: string;
  eventOrigin?: string;
  totalPriceSt?: string;
  filteredItems?: Array<any>;
  refreshCart?: () => void;
  registerEvent?: (params: any) => Promise<void>;
  categoryMap: Map<string, { productList: Product[]; quantity: number; category_id: number }>;
  categoriesList: string[];
  categories: Record<number, CategoryProps>;
  addProductToCart: ({
    sku,
    quantity,
    option_uid,
    uid,
    category_id,
  }: {
    sku: number;
    quantity: number;
    option_uid: string;
    uid: string;
    category_id: number;
  }) => void;
  removeProductFromCart: ({ uid }: { uid: string }) => void;
  updateProductAmount: ({ uid, sku, quantity }: { uid: string; sku: number; quantity: number }) => void;
}

const Summary = (props: SummaryProps) => {
  const { items, cartTotalQuantity, categoryMap, updateProductAmount, addProductToCart, removeProductFromCart } = props;

  const { paymentCode } = useGlobals();
  const { findPrice } = useCalcPrice();
  const { cartTotals } = useCartTotals();
  const { selectedClient } = useClient();
  const { cartDetails, filterTerm } = useCart();
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [accordionSegmentosExpanded, setAccordionSegmentosExpanded] = useState(true);

  const segmentos: { [key: string]: string[] } = useMemo(() => {
    return (
      items?.reduce((acc, i) => {
        if (i.product?.UNILEVER) {}
        const category = i?.category?.DE_CATEGORIA || '';
        const segmentoID = i?.product?.EMBALAGEM?.[0]?.COD_SEGMENTO || '';
        const segmentoDesc = i.product?.UNILEVER ? 'Unilever' : selectedClient?.COD_SEGMENTO?.find((cs) => cs.cod_segmento === segmentoID)?.desc || '';
        const segmento = [...(acc[segmentoDesc] || []), ...(acc[segmentoDesc]?.includes(category) ? [] : [category])];

        return { ...acc, [segmentoDesc]: segmento };
      }, {} as any) || {}
    );
  }, [items, selectedClient]);

  const segmentosKeys = useMemo(() => Object.keys(segmentos), [segmentos]);

  const findSku = useCallback(
    (sku: number) => {
      return cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.find((it) => it.sku === sku);
    },
    [cartDetails, selectedClient?.COD_CLIENTE]
  );

  const findCategory = useCallback(
    (id: number) => {
      return cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.find((it) => it.category_id === id);
    },
    [cartDetails, selectedClient?.COD_CLIENTE]
  );

  // const total = useMemo(() => {
  //   return formatCurrency({ value: cartTotals.total });
  // }, [cartTotals?.total]);

  const subtotal = useMemo(() => {
    return formatCurrency({ value: cartTotals?.subtotal });
  }, [cartTotals?.subtotal]);

  const priceSt = useMemo(() => {
    return formatCurrency({ value: cartTotals?.st_price });
  }, [cartTotals?.st_price]);

  const itemsFiltered = useMemo(() => {
    // if (filterTerm && items?.length) {
    if (!items?.length) return [];
    return items
      ?.filter((i) => i?.product?.DESC_PRODUTO && cleanStr(i?.product?.DESC_PRODUTO)?.includes(cleanStr(filterTerm)))
      ?.map((item) => ({
        ...item?.item,
        ...item?.product,
        category: item?.category,
        packing: item?.item?.option_uid,
        currentPrice: item?.product?.PRECO && findPrice(item?.product?.PRECO, item?.item?.option_uid, paymentCode),
      }));
    // }
  }, [filterTerm, items, findPrice, paymentCode]);

  if (!items?.length) return null;

  return (
    <>
      <S.Container>
        {filterTerm &&
          itemsFiltered?.map((i, idx) => {
            if (findSku(i?.sku)) {
              return (
                <S.CardContainer>
                  <CartProductCard
                    key={`card-item-${idx}-${i?.category_id}`}
                    item={i}
                    addProductToCart={addProductToCart}
                    updateProductAmount={updateProductAmount}
                    removeProductFromCart={removeProductFromCart}
                  />
                </S.CardContainer>
              );
            }
            return null;
          })}

        {segmentosKeys.length && segmentosKeys.map((segmento, ind) => {
            const accordionSegmentosProps = !ind
              ? { accordionExpanded: accordionSegmentosExpanded, setAccordionExpanded: setAccordionSegmentosExpanded }
              : { handleLocalState: true };

            return (
              <Accordion
                noRadius
                showBorder
                showHeader
                noBodyPadding
                showOnlyRightIcon
                maxheight="fit-content"
                accordionTitle={segmento}
                {...accordionSegmentosProps}
                id={`accordion-${segmento}-${ind}`}
              >
                <div style={{ paddingLeft: 1, backgroundColor: '#4f4f4f' }}>
                  {!filterTerm &&
                    segmentos[segmento]?.map((item, index) => {
                      const category = categoryMap.get(item);
                      const accordionProps = !index ? { accordionExpanded, setAccordionExpanded } : { handleLocalState: true };

                      if (category?.quantity && category?.quantity > 0 && findCategory(category?.category_id)) {
                        return (
                          <Accordion
                            maxheight="fit-content"
                            showBorder
                            id={`accordion-${index}`}
                            {...accordionProps}
                            showOnlyRightIcon
                            noRadius
                            noBodyPadding
                            showHeader
                            accordionTitle={`${item} (${category?.quantity})`}
                          >
                            <>
                              {category?.productList?.reverse()?.map((i, idx) => {
                                if (findSku(i?.sku)) {
                                  return (
                                    <>
                                      <CartProductCard
                                        key={`card-item-${idx}-${i.category_id}`}
                                        item={i}
                                        addProductToCart={addProductToCart}
                                        updateProductAmount={updateProductAmount}
                                        removeProductFromCart={removeProductFromCart}
                                      />
                                    </>
                                  );
                                }
                                return null;
                              })}
                            </>
                          </Accordion>
                        );
                      }
                      return null;
                    })}
                </div>
              </Accordion>
            );
          })}

        <S.SubtotalContainer>
          <S.SubtotalContainerRow>
            <S.Subtotal>
              Subtotal ({cartTotalQuantity && cartTotalQuantity > 1 ? `${cartTotalQuantity} itens` : `${cartTotalQuantity} item`})
            </S.Subtotal>
          </S.SubtotalContainerRow>
          <S.Separator />
          <S.TextRow $isfirst={true}>
            <S.RegularText>Subtotal em produtos: </S.RegularText>
            <S.RegularText>{subtotal || 'R$ 0,00'}</S.RegularText>
          </S.TextRow>
          <S.TextRow>
            <S.RegularText>Substituição Tributária: </S.RegularText>
            <S.RegularText>{priceSt || 'R$ 0,00'}</S.RegularText>
          </S.TextRow>
          <S.TextRow $islast={true}>
            <S.RegularText>Frete</S.RegularText>
            <S.RegularText bold color={colors.green}>
              Grátis
            </S.RegularText>
          </S.TextRow>
        </S.SubtotalContainer>
      </S.Container>
    </>
  );
};

export default Summary;
