import Empty from "./empty";
import * as S from "./styles";
import { useCart, useGlobals } from "@/hooks";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { FilterBar, Product, Loading } from "@/components";
import { Directions, CategoryProps, FilterItemProps } from "@/types";
import { useCalcPrice, useDbFunctions, useScrollPageEvent } from "@/talons";

const SearchPage = () => {
  const params = useParams();
  const { id } = params;
  const categoryID = Number(id);
  const isPageBuilder = id?.includes('pagebuilder')
  const isCampanhaVerao = id?.includes('campanha-verao')

  const [loadingIds, setLoadingIds] = useState(true);
  const [productsIds, setProductsIds] = useState([] as number[]);
  const [direction, setDirection] = useState("grid" as Directions);
  const [categories, setCategories] = useState([] as CategoryProps[]);
  const [selectedFilters, setFilters] = useState([] as FilterItemProps[]);
  
  const { getOnDB } = useDbFunctions();
  const { setRouteTitle } = useGlobals();
  const { page, setPage } = useScrollPageEvent();
  const { catalog, isLoaded, substTrib, getCatalog } = useCalcPrice();
  const { addProductToCart, updateProductAmount, removeProductFromCart } = useCart();

  useEffect(() => {
    setPage(1);
  }, [selectedFilters, setPage]);

  useEffect(() => {
    if (!isPageBuilder || productsIds.length || loadingIds) return

    const loadPageBuilder = async () => {
      const list = await getOnDB('pagebuilder');
      const ID = id?.split('-')[1]
      const pagebuilder = list.find(i => `${i.ID}` === `${ID}`)
      const ids = pagebuilder?.list_sku.map(i => Number(i)) || []
      setProductsIds(ids);
      setLoadingIds(false);
    }

    loadPageBuilder()
  }, [setProductsIds, getOnDB, setLoadingIds, isPageBuilder, id, productsIds, loadingIds]);

  useEffect(() => {
    if (!isCampanhaVerao || productsIds.length || !loadingIds) return

    const loadPageCampanha = async () => {
      const campanha = await getOnDB('campanhaProduto');
      setProductsIds(campanha.ativo?.produto || []);
      setLoadingIds(false);
    }

    loadPageCampanha()
  }, [setProductsIds, getOnDB, setLoadingIds, isCampanhaVerao, productsIds, loadingIds]);

  useEffect(() => {
    if (isLoaded.current) return;

    getCatalog();
  }, [getCatalog, isLoaded]);

  const catalogList = useMemo(() => {
    if (!productsIds.length) return catalog;
    return catalog.filter((p) => productsIds.includes(p.COD_PRODUTO));
  }, [productsIds, catalog]);

  const allCategories = useMemo(() => {
    const findDescendants = (items: CategoryProps[], fatherID: number) => {
      let descendants = [] as CategoryProps[];

      const findChildren = (id: number) => {
        const children = items.filter((item) => item.COD_CATEGORIA_PAI === id);
        children.forEach((child) => {
          descendants.push(child);
          findChildren(child.COD_CATEGORIA);
        });
      };

      findChildren(fatherID);
      return descendants;
    };

    return [
      categoryID,
      ...findDescendants(categories, categoryID).map((i) => i.COD_CATEGORIA),
    ];
  }, [categoryID, categories]);

  const searchList = useMemo(() => {
    if (productsIds.length) return catalogList;
    return catalogList.filter((p) => allCategories.includes(p.COD_CATEGORIA));
  }, [catalogList, allCategories, productsIds]);

  const filteredList = useMemo(() => {
    if (!selectedFilters.length) return searchList;

    const ids = selectedFilters.map((i) => i.id);

    return catalogList.filter((i) => {
      const isBrandFilter = selectedFilters.some((f) => f.id === i.COD_MARCA);
      if (isBrandFilter) return true;

      const isCatFilter = selectedFilters.find((f) => f.id === i.COD_CATEGORIA);

      return (
        isCatFilter && !isCatFilter?.brands?.some((item) => ids.includes(item))
      );
    });
  }, [selectedFilters, catalogList, searchList]);

  const titlePage = useMemo(() => {
    return categories.find((c) => c.COD_CATEGORIA === categoryID)?.DE_CATEGORIA;
  }, [categories, categoryID]);

  useEffect(() => {
    if (titlePage) setRouteTitle(titlePage);
  }, [setRouteTitle, titlePage]);

  const count = useMemo(() => filteredList?.length, [filteredList]);

  const isPlural = useMemo(() => (count !== 1 ? "s" : ""), [count]);

  const listFilterProducts = useMemo(() => {
    if (isCampanhaVerao || isPageBuilder) return catalogList;

    return searchList.length ? searchList : catalogList;
  }, [searchList, catalogList, isCampanhaVerao, isPageBuilder]);

  return (
    <S.Content id="category-page">
      <S.Container>
        <FilterBar
          direction={direction}
          categoryId={categoryID}
          filters={selectedFilters}
          setFilters={setFilters}
          setDirection={setDirection}
          setCategories={setCategories}
          products={listFilterProducts}
        />

        {!categories.length ? (
          <S.ContentLoading>
            <Loading scale={2} />
          </S.ContentLoading>
        ) : (
          <>
            <S.Separator />

            {!!count && (
              <S.Counter>
                {count} produto{isPlural} encontrado{isPlural}
              </S.Counter>
            )}

            <S.ContentList $isgrid={direction === "grid"}>
              {filteredList?.slice(0, page * 10).map((product, ind) => (
                <Product
                  product={product}
                  substTrib={substTrib}
                  direction={direction}
                  key={`search-product-${product.COD_PRODUTO}-${ind}`}
                  addProductToCart={addProductToCart}
                  updateProductAmount={updateProductAmount}
                  removeProductFromCart={removeProductFromCart}
                />
              ))}
            </S.ContentList>

            {(!categories.length || !filteredList.length) && <Empty />}
          </>
        )}
      </S.Container>
    </S.Content>
  );
};

export default SearchPage;
