import { ClientProps, ClientAddressProps } from "@/types";
import { getOnStorage, removeFromStorage, setOnStorage } from "@/shared";
import { useDbFunctions } from "@/talons";

import {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from "react";

interface HookProps {
  logoutClient: () => void;
  allClients: ClientProps[];
  selectedClient: ClientProps;
  selectedClientAddress: ClientAddressProps;
  setAllClients: (clients: ClientProps[]) => void;
  setSelectedClient: (client: ClientProps) => void;
  calculateDeliveryDate: () => string;
  janelaEntregaData: any;
  setJanelaEntregaData: (data: any) => void;
}

const ConfigsContex = createContext<HookProps>({} as HookProps);

const ClientProvider: React.FC<any> = ({ children }) => {
  const { getOnDB } = useDbFunctions();
  const [isLoaded, setIsLoaded] = useState(false);

  const client = getOnStorage("selected_client");
  const [allClients, setAllClients] = useState([] as ClientProps[]);
  const [selectedClient, setHookSelectedClient] = useState({} as ClientProps);
  const [selectedClientAddress, setSelectedClienteAddress] = useState(
    {} as ClientAddressProps
  );

  const [janelaEntrega, setJanelaEntrega] = useState<any>(null);
  const [janelaEntregaData, setJanelaEntregaData] = useState<any>(null);

  // Função para buscar janelaEntrega
  const fetchJanelaEntrega = useCallback(async () => {
    try {
      const result = await getOnDB("janelaEntrega");
      setJanelaEntrega(result);  // Armazena o resultado no estado
    } catch (error) {
      console.error("Erro ao buscar janelaEntrega:", error);
    }
  }, [getOnDB]);

  const calculateDeliveryDate = useCallback(() => {
    const cod_rota = client?.COD_ROTA;
    const days = {
      1: 'Domingo',
      2: 'Segunda-feira',
      3: 'Terça-feira',
      4: 'Quarta-feira',
      5: 'Quinta-feira',
      6: 'Sexta-feira',
      7: 'Sábado',
    };

    const janelasEntrega = janelaEntrega?.filter((row: any) => row.COD_ROTA === cod_rota);
    if (!janelasEntrega?.length) {
      return 'Nenhuma janela de entrega disponível no momento.'
    }

    const now = new Date();
    const weekDayNow = now.getDay();
    const hour = now.getHours();

    const weekDay = weekDayNow === 0 ? 7 : weekDayNow;

    for (let janela of janelasEntrega) {
      const initialDay = janela.DIA_INT_INICIAL;
      const initialHour = janela.HORA_INT_INICIAL;
      const finalDay = janela.DIA_INT_FINAL;
      const finalHour = janela.HORA_INT_FINAL;

      const isWithinRange =
        (weekDay > initialDay || (weekDay === initialDay && hour >= initialHour)) &&
        (weekDay < finalDay || (weekDay === finalDay && hour < finalHour));

      if (isWithinRange) {
        // console.log(`Data prevista de entrega: ${janela.DATA_PREVISTA_ENTREGA} (${days[initialDay as keyof typeof days]})`)
        return `Data prevista de entrega: ${janela.DATA_PREVISTA_ENTREGA} (${days[initialDay as keyof typeof days]})`
      }
    }
    return 'Nenhuma janela de entrega disponível no momento.';
  }, [client, janelaEntrega]);

  const setSelectedClient = useCallback(
    (newClient: ClientProps) => {
      setOnStorage("selected_client", newClient);
      setHookSelectedClient(newClient);
    },
    [setHookSelectedClient]
  );

  const logoutClient = useCallback(() => {
    removeFromStorage("selected_client");
    setHookSelectedClient({} as ClientProps);
    setSelectedClienteAddress({} as ClientAddressProps);
    localStorage.setItem("@PWA_RCA:selected_client", "");
    setIsLoaded(false);
  }, [setHookSelectedClient]);

  const loadStorageDatas = useCallback(async (client: any) => {
    const clientAddress = {
      street: client.ENDERECO,
      address_number: client.NUMERO,
      region: client.BAIRRO,
      state: client.ESTADO,
      city: client.CIDADE,
      name: client.NM_CLIENTE,
      cep: client.CEP,
      telephone: client.NR_TELEFONE,
    };
    setHookSelectedClient(client);
    setSelectedClienteAddress(clientAddress);

    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (!janelaEntrega && client) {
      fetchJanelaEntrega();
    }
  }, [janelaEntrega, client, fetchJanelaEntrega]);

  useEffect(() => {
    if (!isLoaded && client) {
      loadStorageDatas(client);
    }
  }, [isLoaded, client, loadStorageDatas]);

  return (
    <ConfigsContex.Provider
      value={{
        allClients,
        selectedClient,
        selectedClientAddress,
        calculateDeliveryDate,
        janelaEntregaData,

        logoutClient,
        setAllClients,
        setSelectedClient,
        setJanelaEntregaData
      }}
    >
      {children}
    </ConfigsContex.Provider>
  );
};

const useClient = (): HookProps => {
  const ctx = useContext(ConfigsContex);

  if (!ctx) throw new Error("Erro ao usar hook Header");

  return ctx;
};

export { ClientProvider, useClient };
