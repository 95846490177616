import styled from "styled-components";
import { colors } from "@/constants";
import { RiSearchLine, RiEqualizerLine } from "react-icons/ri";

export const SearchIcon = styled(RiSearchLine) <{ $isgray?: boolean }>`
  color: ${(i) => (i.$isgray ? colors.gray.gray40 : colors.brand.white)};
`;

export const SearchContent = styled.div`
  display: inline-flex;
  justify-content: end;
  align-items: center;
`;

export const SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  width: 100%;
  padding: 20px;
  background-color: ${colors.brand.grafite};

  position: sticky;
  top: 0;  
`;

export const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  flex-grow: 1;

  height: 40px;
  border-radius: 6px;
  background-color: ${colors.brand.white};

  svg{
    color: ${colors.brand.grafite} !important;
  }
`;

export const SearchInput = styled.input`
  border: none;
  outline: none;

  width: 100%;
  
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  color: ${colors.gray.gray40};
`;

export const CloseSearch = styled.span`
  font-size: 14px;
  line-height: 16.41px;
  font-weight: 500;
  line-height: 16.41px;
  color: ${colors.brand.white};
`;

export const PageContainer = styled.div`
  padding-bottom: 80px;
  min-height: 100vh;
  background-color: ${colors.gray.gray10};
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LoadingContainer = styled.div`
  /* text-align: center; */
  display: flex;
  align-items: center;
  height: 50vh;
  justify-content: center;
  /* padding-bottom: 20vh; */
`;

export const FilterHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;

  font-size: 14px;
  font-weight: 400;
`;

export const FilterButton = styled.div`
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  position: relative;
  align-items: center;
  display: inline-flex;
  color: ${colors.brand.grafite};
`;

export const FilterIcon = styled(RiEqualizerLine)`
  width: 20px;
  height: 20px;
`;

export const ClearFilter = styled.div`
  color: ${colors.red};
`;

export const CollapsibleFilterBox = styled.div<{ isVisible: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 100%;
    padding: ${({ isVisible }) => (isVisible ? '16px' : '0 16px')};
    background-color: ${colors.background1};
    border-radius: 6px;
    border: solid 1px ${colors.gray.gray10};

    max-height: ${({ isVisible }) => (isVisible ? '300px' : '0')}; // Controla a altura máxima
    overflow: hidden; // Esconde o conteúdo que ultrapassa o max-height
    transition: max-height 0.3s ease, padding 0.3s ease; // Animação suave
`;