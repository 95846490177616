import "react-datepicker/dist/react-datepicker.css";

import * as S from './styles';
import { colors } from '@/constants';
import { ptBR } from 'date-fns/locale';
import DatePicker from "react-datepicker";
import { ClientProps, ProductProps } from '@/types';
import { useDbFunctions, useOrderStatusPage } from '@/talons';
import { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { OrderItemsProps, OrderProps } from '@/talons/orderStatusPage/useOrderStatusPage';
import { OrderStatusCalendar as Calendar, OrderStatusFullCard as Card, Loading, Header } from '@/components';

interface ParsingDateProps {
  dia: number;
  mes: number;
  ano: number;
}

const parsingDates = (dateStr: string): ParsingDateProps => {
  if (!dateStr) {
    console.error('Invalid date string:', dateStr);
    return { dia: 0, mes: 0, ano: 0 }
  }

  const [, date, month, year] = dateStr.split(/[ ,/]+/);

  return {
    dia: Number(date),
    mes: Number(month),
    ano: Number(year),
  }
};

const groupByDate = (orders: OrderProps[]) => {
  return orders?.reduce((groups, order) => {
    const date = order.orders.orderDate;
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(order);

    return groups;
  }, {} as Record<string, OrderProps[]>);
};

const OrderStatusPage = () => {
  const { getOnDB } = useDbFunctions();
  const { ordersList, offlineOrdersList, synchedOrdersList, loading, ordersDate, filterDate, setFilterDate } = useOrderStatusPage();

  const [filter, setFilter] = useState('');
  const [openSearch, setOpenSearch] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [allClients, setClientList] = useState({} as Record<string, ClientProps>);
  const [productsDict, setProductsDict] = useState({} as Record<number, ProductProps>);

  const loadProductDetails = useCallback(async () => {
    const dict = await getOnDB("dicionario_produtos");
    setProductsDict(dict);
  }, [getOnDB]);

  useEffect(() => {
    if (productsDict) return

    loadProductDetails();
  }, [loadProductDetails, productsDict]);

  const loadClients = useCallback(async () => {
    setIsLoadingClients(true)
    const client = await getOnDB("clientes").then(clients => {
      return clients?.reduce((acc, client) => ({ ...acc, [`${client.COD_CLIENTE}`]: client }), {}) || []
    });
    setIsLoadingClients(false)
    setClientList(client as Record<string, ClientProps>);
  }, [getOnDB, setIsLoadingClients]);

  useEffect(() => {
    if (isLoadingClients || Object.keys(allClients || {}).length) return;

    loadClients();
  }, [loadClients, allClients, isLoadingClients]);

  const mostRecentDate = useMemo(() => {
    if (ordersDate && ordersDate.length > 0) {
      return new Date(Math.max(...ordersDate.map((date: any) => date.getTime())));
    }
    return new Date();
  }, [ordersDate]);

  const combinedOrdersList: any[] = useMemo(() => [...ordersList, ...offlineOrdersList, ...synchedOrdersList], [ordersList, offlineOrdersList, synchedOrdersList]);
  console.log({combinedOrdersList});
  

  const filteredList = useMemo(() => {
    return combinedOrdersList.filter(ord => !combinedOrdersList.includes((comb: any) => {
      return comb.orders.orderNumber === ord.orders.order_number_origem
    }))
  }, [combinedOrdersList])

  
  const sortedGroupedOrders = useMemo(() => {
    const groupedOrders = groupByDate(filteredList);

    return Object.keys(groupedOrders)
      .sort((a, b) => {

        const dateA = parsingDates(a);
        const dateB = parsingDates(b);

        const dateStrA = `${dateA?.ano}-${dateA?.mes}-${dateA?.dia}`;
        const dateStrB = `${dateB?.ano}-${dateB?.mes}-${dateB?.dia}`;

        const parseB = new Date(dateStrB)?.getTime();
        const parseA = new Date(dateStrA)?.getTime();

        return parseB - parseA;
      })
      .map(date => {
        let total = 0;
        const clientList = [] as string[];
        const ordersMap = new Map() as Record<string, any>;

        const list = groupedOrders[date];

        list.forEach(i => {
          if (!ordersMap?.has(i.clientRefNumber)) {
            ordersMap.set(i.clientRefNumber, {
              clientName: i.clientName,
              clientRefNumber: i.clientRefNumber,
              clientCnpj: i?.clientCnpj,
              orders: [{ ...i.orders }],
            });
            total += Number(i.orders.total ?? 0)

            clientList.push(i.clientRefNumber);
          } else if (ordersMap?.has(i.clientRefNumber)) {
            const val = ordersMap.get(i.clientRefNumber);
            total += Number(i.orders.total ?? 0)
            const orders = [...val?.orders, { ...i.orders }] as OrderItemsProps[];
            ordersMap.set(i.clientRefNumber, { ...val, orders });
          }
        })

        return { date, total, ordersMap, clientList }
      })
    }
  , [filteredList])

  const cleanFilter = () => {
    setFilter('');
    setOpenSearch(false);
  }

  if (loading || isLoadingClients) {
    return (
      <S.PageContainer>
        <Header rightIcon={<S.SearchContent />} />

        <S.LoadingContainer>
          {loading || isLoadingClients ? <Loading scale={1.5} /> :
            <p style={{ color: colors.gray.gray60 }}>Nenhum pedido feito até o momento</p>
          }
        </S.LoadingContainer>
      </S.PageContainer>
    )
  }

  return (
    <S.PageContainer>
      {!openSearch ? (
        <Header
          rightIcon={
            <S.SearchContent>
              <S.SearchIcon size={20} data-test="my-clients-button-search" onClick={() => setOpenSearch(true)} />
            </S.SearchContent>
          }
        />
      ) : (
        <S.SearchHeader>
          <S.SearchInputWrapper>
            <S.SearchIcon size={20} data-test="my-clients-input-search" $isgray />
            <S.SearchInput
              ref={searchInputRef}
              type="text"
              placeholder="Busque por pedido..."
              onChange={(event: any) => setFilter(event.target.value.trim())}
            />
          </S.SearchInputWrapper>
          <S.CloseSearch onClick={() => cleanFilter()}>Cancelar</S.CloseSearch>
        </S.SearchHeader>
      )}

      <S.FilterHeader>
        <S.FilterButton onClick={() => setShowDateFilter(!showDateFilter)} key="filterDateButton" data-test="pedido-filter-date-button">
          <S.FilterIcon />
          Filtrar
        </S.FilterButton>

        {filterDate && (
          <S.ClearFilter onClick={() => setFilterDate(undefined)}>
            Remover filtro
          </S.ClearFilter>
        )}
      </S.FilterHeader>
      {showDateFilter && (
        <S.CollapsibleFilterBox isVisible={true}>
          <DatePicker
            selected={filterDate}
            onChange={(date: any) => setFilterDate(date)}
            highlightDates={ordersDate}
            openToDate={mostRecentDate}
            inline
            placeholderText="Selecione uma data"
            isClearable
            showYearDropdown
            locale={ptBR}
          />
        </S.CollapsibleFilterBox>
      )}

      {sortedGroupedOrders.map(({ date, ordersMap, clientList, total }, groupIndex) => {
        return (<S.PageContent key={`group-${groupIndex}`}>
          {date !== undefined && !filter && (
            <Calendar date={date} total={total} />
          )}

          {clientList?.map((clientNumber, idx) => {
            const mapOrder = ordersMap.get(clientNumber);
            const clientName = mapOrder?.clientName;
            const clientCnpj = mapOrder?.clientCnpj;
            let ordersList = mapOrder?.orders;
            let totalOrders;

            if (filter) {
              ordersList = ordersList.filter((order: any) => order?.orderNumber?.includes(filter) ||
                filter.length > 0 && clientName.toLowerCase().includes(filter.toLowerCase()) ||
                clientCnpj?.includes(filter) ||
                filter.length > 0 && clientNumber.includes(filter) ||
                order?.status?.toString().toLowerCase().includes(filter.toLowerCase())
              );

              totalOrders = ordersList.reduce((acc: number, order: any) => acc + Number(order?.total), 0);
            }
            if (mapOrder && ordersList.length > 0) return (
              <>
                {filter && <Calendar date={date} total={totalOrders} />}
                <Card
                  date={date}
                  orders={ordersList}
                  clientName={clientName}
                  clientCnpj={clientCnpj}
                  productsDict={productsDict}
                  clientRefNumber={clientNumber}
                  key={`card-${groupIndex}-${idx}`}
                  client={allClients[clientNumber] || {}}
                />
              </>

            )
            return null;
          }
          )}
        </S.PageContent>
        )
      })}
    </S.PageContainer>
  )
}

export default OrderStatusPage;
