import { useClient } from "./ClientContext";
import { useGlobals } from "./GlobalContext";
import { CartItems, useCart } from "./CartContext";
import { useDiscountFlex } from "./DiscountFlexContext";
import { useCalcPrice, useDbFunctions } from "@/talons";
import { ClientProps, PriceProductProps, ProductProps, TaxSubstitutionProps } from "@/types";

import {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from "react";
import { HandlePrices } from "@/shared/calculatePrices";

interface CartToTalsProps {
  cartTotals: {
    total: number;
    subtotal: number;
    st_price: number;
  };
  loading: boolean;
  fetchCorrectPrice: (
    price: PriceProductProps[],
    option_uid: string,
    paymentCondition: number
  ) => PriceProductProps | {
    VLR_PRECO: number;
    VLR_FINAL: number;
    ST_VLR_FINAL?: number;
    VLR_PRECO_CALCULADO?: number;
    COD_TRIBUTACAO_PRODUTO?: number;
    VLR_PAUTA?: number;
  };
  fetchTotalPriceByPaymentCode: (code: number) => number;
  listaTributacao: TaxSubstitutionProps[];
}

export const CartTotalsContext = createContext({} as CartToTalsProps);

const CartTotalsProvider = ({ children }: React.PropsWithChildren) => {
  const [cartTotals, setCartTotals] = useState(
    {} as {
      total: number;
      subtotal: number;
      st_price: number;
    }
  );

  const { handlePrices } = useCalcPrice();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState({} as Record<number, ProductProps>);
  const [listaTributacao, setListaTributacao] = useState([] as TaxSubstitutionProps[]);

  const { selectedClient } = useClient();
  const { cartDetails } = useCart();
  const { paymentCode } = useGlobals();
  const { getDiscountProduct } = useDiscountFlex();
  const { getOnDB } = useDbFunctions();

  const fetchProductsDict = useCallback(async () => {
    setLoading(true);

    const codLoja = selectedClient?.COD_LOJA;
    const tabVenda = selectedClient?.COD_TABELA_VENDA;
    const condicoes = selectedClient?.COD_PAGAMENTO?.map((i) => i.cod_condicao);

    const listSubstTrib = await getOnDB("substituicaotributaria");
    const parametroPrecoBase = await getOnDB("parametroPrecoBase").then(
      (list) => {
        return list?.filter(
          (p) =>
            `${p.NROTABVENDA}` === `${tabVenda}` &&
            condicoes?.includes(p.NROCONDICAOPAGTO) &&
            codLoja === p.COD_LOJA
        );
      }
    );

    const percentualFornecedor = await getOnDB("percentualFornecedor").then(
      (list) => {
        return list?.filter((p) => p.NROTABVENDA === `${tabVenda}`);
      }
    );

    const parametroPrecoPromocao = await getOnDB("precoPromocaoCliente")

    const products_dict = await getOnDB("dicionario_produtos").then((res) => {
      return Object.entries(res || {})?.reduce((acc, [key, item]) => {
        item.PRECO = handlePrices(
          condicoes || [],
          item,
          parametroPrecoBase,
          percentualFornecedor,
          listSubstTrib,
          parametroPrecoPromocao,
        );
        return { ...acc, [key]: item };
      }, {});
    });

    if (products_dict) setProducts(products_dict);
    if (listSubstTrib) {
      setListaTributacao(listSubstTrib);
    }
    setLoading(false);
  }, [
    getOnDB,
    handlePrices,
    selectedClient?.COD_LOJA,
    selectedClient?.COD_PAGAMENTO,
    selectedClient?.COD_TABELA_VENDA,
  ]);

  const dict = useMemo(() => products, [products]);

  const isLoaded = useRef(false);
  useEffect(() => {
    if (isLoaded.current || !selectedClient?.COD_CLIENTE) return;
    isLoaded.current = true;

    fetchProductsDict();
  }, [fetchProductsDict, selectedClient?.COD_CLIENTE]);

  const fetchCorrectPrice = useCallback(
    (
      price: PriceProductProps[],
      option_uid: string,
      paymentCondition: number
    ) => {
      const [packing, quantity] = option_uid?.split("_");

      const correctPrice = price?.find((p) => {
        if (p?.QTD_MULTIPLO_VENDA > 1) {
          return (
            p.SG_EMBALAGEM === packing &&
            p.QTD_MULTIPLO_VENDA?.toString() === quantity &&
            p.NROCONDICAOPAGTO === paymentCondition
          )
        }
        return (
          p.SG_EMBALAGEM === packing &&
          p.QTD_EMBALAGEM?.toString() === quantity &&
          p.NROCONDICAOPAGTO === paymentCondition
        );
      });

      return (
        correctPrice || {} as PriceProductProps
      );
    },
    []
  );

  const fetchTotalPriceByPaymentCode = useCallback(
    (code: number) => {
      if (
        cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.length > 0 &&
        code
      ) {
        const totals = cartDetails?.[
          selectedClient?.COD_CLIENTE
        ]?.items?.reduce((acc: number, item: CartItems) => {
          const curDic = dict?.[item?.sku];

          if (curDic?.PRECO?.length > 0) {
            const [packing, qtd] = item?.option_uid?.split("_");

            const discountprice = getDiscountProduct({
              package: packing,
              productId: item?.sku,
              quantity: Number(qtd),
            })?.discountPrice?.[`${code}`];

            const price = curDic?.PRECO?.find((i) => {
              if (i?.QTD_MULTIPLO_VENDA > 1) {
                return (
                  i?.QTD_MULTIPLO_VENDA?.toString() === qtd &&
                  i?.SG_EMBALAGEM === packing &&
                  i?.NROCONDICAOPAGTO === code
                )
              }
              return (
                i?.QTD_EMBALAGEM?.toString() === qtd &&
                i?.SG_EMBALAGEM === packing &&
                i?.NROCONDICAOPAGTO === code
              );
            });

            const finalprice =
              discountprice || price?.VLR_FINAL || price?.VLR_PRECO;

            if (finalprice) {
              const fullprice = finalprice * item?.quantity;
              if (fullprice) return (acc += fullprice);
            }
          }
          return acc;
        }, 0);
        return totals;
      }
      return 0;
    },
    [cartDetails, dict, getDiscountProduct, selectedClient?.COD_CLIENTE]
  );

  const handleCalcStTotals = useCallback((code: number) => {
    if (cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.length > 0 && code) {
      const stTotals = cartDetails?.[
        selectedClient?.COD_CLIENTE
      ]?.items?.reduce((acc: number, item: CartItems) => {
        const curDic = dict?.[item?.sku];

        if (curDic?.PRECO?.length > 0) {
          const [packing, qtd] = item?.option_uid?.split("_");

          const price = curDic?.PRECO?.find((i) => {

            if (i?.QTD_MULTIPLO_VENDA > 1) {
              return (
                i?.QTD_MULTIPLO_VENDA?.toString() === qtd &&
                i?.SG_EMBALAGEM === packing &&
                i?.NROCONDICAOPAGTO === code
              )
            }
            return (
              i?.QTD_EMBALAGEM?.toString() === qtd &&
              i?.SG_EMBALAGEM === packing &&
              i?.NROCONDICAOPAGTO === code
            );
          });

          // console.log('PRECO ->>>>>>>..', price);
          if (price?.DESTACA_ST === 'S' && selectedClient?.COD_LOJA === 743 && listaTributacao?.length && price?.VLR_PRECO_CALCULADO) {
            const tributacao = HandlePrices.getTributacao(selectedClient, price?.COD_TRIBUTACAO_PRODUTO, listaTributacao);

            const precoSt = HandlePrices.CalculaST(tributacao, price?.VLR_PRECO_CALCULADO * item?.quantity, price?.VLR_PAUTA);

            if (precoSt) {
              return acc += precoSt;
            }
            return acc;
          }
          return acc;
        }
        return acc;
      }, 0);
      return stTotals;
    }
    return 0;
  }, [cartDetails, dict, listaTributacao, selectedClient]);

  useEffect(() => {
    if (dict) {
      if (
        cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.length > 0 &&
        paymentCode
      ) {
        const totals = fetchTotalPriceByPaymentCode(paymentCode);
        const stTotals = handleCalcStTotals(paymentCode);
        setCartTotals({
          total: totals,
          subtotal: totals - stTotals,
          st_price: stTotals,
        });
      }
    }
  }, [
    fetchTotalPriceByPaymentCode,
    cartDetails,
    paymentCode,
    selectedClient,
    dict,
    handleCalcStTotals,
  ]);

  const context = useMemo(
    () => ({
      loading,
      cartTotals,
      fetchCorrectPrice,
      fetchTotalPriceByPaymentCode,
      listaTributacao,
    }),
    [cartTotals, loading, fetchCorrectPrice, fetchTotalPriceByPaymentCode, listaTributacao]
  );

  return (
    <CartTotalsContext.Provider value={context}>
      {children}
    </CartTotalsContext.Provider>
  );
};

const useCartTotals = () => {
  const context = useContext(CartTotalsContext);

  if (!context) {
    throw new Error(
      "o context useCartTotals só pode ser utilizado dentro de seu escopo. Erro ao usar hook useCartTotals"
    );
  }
  return context;
};

export { CartTotalsProvider, useCartTotals };
