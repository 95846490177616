import { isSameDay } from "date-fns";
import { ProductProps } from "@/types";
import { getOnStorage } from "@/shared";
import { useDbFunctions } from "../dbFunctions/useDBFunctions";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFetchAxios, useNetworkStatusContext, useUserContext } from "@/hooks";

export interface OrderProps {
  clientName: string;
  clientRefNumber: string;
  clientCnpj: string;
  orders: OrderItemsProps;
}
export interface OrderItemsProps {
  orderNumber: string;
  erpNumberClient?: string;
  erpNumber: string;
  order_number_origem?: string;
  origin: string;
  orderNumberMagento: string;
  quantity: number | string;
  status: string;
  isLocal?: boolean;
  reason: string;
  orderDate: string;
  total: string;
  vlr_comissao?: number;
  paymentTerms: string;
  actionsPending: ItemsProps[];
}
export interface ItemsProps extends ProductProps {
  cod_segmento: number;
  sku: string;
  product: string;
  price: string;
  unitPrice: string;
  unitPricePerItem: string;
  stPrice: string;
  stPricePackaging: number;
  integrationPrice: number;
  integrationPriceFlex: number;
  quantity: number;
  quantityMultiplo: number;
  percentualFlex?: string;
  packaging: string;
  status: string;
  reason: string;
}

export const useOrderStatusPage = () => {
  const { getOnDB } = useDbFunctions();
  const { customer } = useUserContext();
  const { axiosCtx: axiosInstance } = useFetchAxios();
  const { connectivityStatus } = useNetworkStatusContext();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filterDate, setFilterDate] = useState<Date>();
  const [error, setError] = useState<Error | null>(null);
  const [orders, setOrders] = useState<OrderProps[]>([]);
  const [offlineOrders, setOfflineOrders] = useState<OrderProps[]>([]);
  const [products, setProducts] = useState<Record<number, ProductProps>>({});
  const [synchedOrdersList, setSynchedOrdersList] = useState<OrderProps[]>([]);

  const isLoadedProducts = useMemo(() => Object.keys(products || {})?.length, [products])

  useEffect(() => {
    if (loading || data ||isLoadedProducts) return

    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`${window.agp_config.REACT_APP_AWS}/pedido-notifica-get?seqpessoa=${customer.codPessoa}`)
          .then(res => res)
          .catch(e => {
            console.log('deu ruim: ', e);
            return e;
          });

        const productsDB = await getOnDB("dicionario_produtos");
        setProducts(productsDB);
        setData(response?.data?.pedidos);
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [axiosInstance, customer.codPessoa, data, getOnDB, loading, isLoadedProducts]);

  const mountOrders = useCallback((orders: any, isLocal?: boolean) => {
    return orders?.map((order: any) => ({
      clientName: order?.company?.name,
      clientRefNumber: order?.company?.id,
      clientCnpj: order?.company?.cnpj,
      orders: {
        isLocal,
        orderNumber: order?.orders?.order_number,
        erpNumber: order?.orders?.erp_order_number,
        orderNumberMagento: order?.orders?.order_number_magento,
        quantity: order?.orders?.items,
        status: order?.orders?.status || 'indisponível',
        reason: order?.orders?.reason,
        orderDate: order?.orders?.order_date,
        total: order?.orders?.total,
        vlr_comissao: order?.orders?.vlr_comissao,
        paymentTerms: order?.orders?.payment_terms,
        actionsPending: order?.orders?.actions_pending?.map((item: any) => ({
          cod_segmento: item?.cod_segmento,
          sku: item?.sku,
          product: item?.product,
          price: item?.price,
          unitPrice: item?.unit_price,
          unitPricePerItem: item?.unit_price_per_item,
          stPrice: item?.st_price,
          stPricePackaging: item?.st_price_packaging,
          integrationPrice: item?.integration_price,
          integrationPriceFlex: item?.integration_price_flex,
          quantity: item?.quantity,
          quantityMultiplo: item?.quantity_multiplo,
          packaging: item?.packaging,
          percentualFlex: item?.percentual_flex,
          status: '',
          reason: '',
          ...products[item?.sku],
        }))
      }
    }));
  }, [products])

  useEffect(() => {
    if ((offlineOrders?.length && synchedOrdersList?.length) || !isLoadedProducts) return;

    const fetchOfflineData = () => {
      const synchedOrders = getOnStorage("synched_orders");
      const offlineOrdersStr = getOnStorage("offline_orders");

      if (offlineOrdersStr && !offlineOrders?.length) {
        const ordersOffline = mountOrders(offlineOrdersStr, true);
        setOfflineOrders(ordersOffline);
      }

      if (synchedOrders && !synchedOrdersList?.length) {
        const synchedList = mountOrders(synchedOrders, true);
        setSynchedOrdersList(synchedList);
      }
    };

    fetchOfflineData();
  }, [getOnDB, mountOrders, synchedOrdersList, offlineOrders, isLoadedProducts]);

  useEffect(() => {
    if (!isLoadedProducts) return;

    const orders = mountOrders(data)
    setOrders(orders);
  }, [mountOrders, data, isLoadedProducts]);

  const ordersDate = useMemo(() => {
    return orders?.map((order: any) => {
      const dateString = order?.orders?.orderDate?.replace(/^[^,]*,\s*/, '');
      return new Date(`${dateString.split('/').reverse().join('-')}T00:00:00`);
    });
  }, [orders]);

  const filteredOrders = useMemo(() => {
    if (!orders?.length) return [];
    
    if (!filterDate) return orders;
    
    return orders.filter((order) => {
      const orderDate = new Date(`${order?.orders?.orderDate?.replace(/^[^,]*,\s*/, '').split('/').reverse().join('-')}T00:00:00`);
      return isSameDay(orderDate, filterDate);
    });
  }, [orders, filterDate]);

  const filteredSynchedOrdersList = useMemo(() => {
    if (!synchedOrdersList?.length) return [];

    return synchedOrdersList.filter(syn => !filteredOrders.find(filOrd => filOrd.orders.orderNumber.includes(syn.orders.orderNumber)));
  }, [synchedOrdersList, filteredOrders]);

  return {
    error,
    loading,
    filterDate,
    ordersDate,
    ordersList: filteredOrders,
    synchedOrdersList: filteredSynchedOrdersList,
    offlineOrdersList: connectivityStatus === 'offline' ? offlineOrders : [],
    setFilterDate,
  };
}
