import * as S from "./styles";
import { useMemo } from "react";
import { colors } from "@/constants";
import { RiLoopRightLine } from "react-icons/ri";
import { PaymentTypes, formatCurrency } from "@/shared";

type PaymentCodeTypes = "7" | "14" | "21" | "28" | "35" | "114" | "121" | "128" | "135";
interface UpdateOrderStatusProps {
  quantity: number;
  grandTotal: string;
  isLoading?: boolean;
  processOrder: () => void;
  paymentCondition: number;
  canProcessOrder: boolean;
}

export const UpdateOrderStatus = ({ quantity, grandTotal, processOrder, canProcessOrder, paymentCondition, isLoading }: UpdateOrderStatusProps) => {
  const currentPayment = useMemo(() => {
    const index = paymentCondition?.toString() as PaymentCodeTypes;
    return PaymentTypes?.[index]?.label;
  }, [paymentCondition]);

  return (
    <S.Container>
      <S.Button>
        <S.PaymentCondition>
          <S.Icon size={20} color={colors.brand.grafite} />

          <S.PaymentSelected>{currentPayment}</S.PaymentSelected>
        </S.PaymentCondition>
      </S.Button>
      <S.TotalsContainer>
        <S.TotalLabel>
          Total
          <S.CartQuantityLabel>
            {quantity > 1
              ? `(${quantity} itens)`
              : `(${quantity} item)`}
          </S.CartQuantityLabel>
        </S.TotalLabel>
        <S.Total>{formatCurrency({ value: grandTotal })}</S.Total>
      </S.TotalsContainer>
      
      <S.LinkButton disabled={!grandTotal || !canProcessOrder} $disabled={!grandTotal || !canProcessOrder} onClick={processOrder} data-test={"update-order-button"}>
        <S.Loading $isLoading={isLoading}>
          <RiLoopRightLine color={colors.brand.white} size={15} />
        </S.Loading>

        <S.ButtonLabel>Atualizar pedido</S.ButtonLabel>
      </S.LinkButton>
    </S.Container>
  );
};

export default UpdateOrderStatus;