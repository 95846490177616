import * as S from './styles';
import { colors } from '@/constants';
import { formatCurrency } from '@/shared';
import { useMemo, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { CartItems } from '@/hooks/CartContext';
import { RiBarcodeBoxLine } from 'react-icons/ri';
import { RiInformationLine } from 'react-icons/ri';
import { useCart, useGlobals, useDiscountFlex, useClient, useStock } from '@/hooks';
import { AddToCart, Packaging, ModalFlexDiscount, ModalCustomAttributes, StPrice, Stock } from '@/components';
import { Directions, PackagesTypesProps, PackagingProductProps, PriceProductProps, ProductProps, TaxSubstitutionProps } from '@/types';

interface AddProductProps {
  sku: number;
  quantity: number;
  option_uid: string;
  uid: string;
  category_id: number;
}

interface UpdateProductAmountProps {
  sku: number;
  uid: string;
  quantity: number;
}

interface Product {
  showTime?: boolean;
  product: ProductProps;
  showInfos?: boolean;
  showFlex?: boolean;
  direction?: Directions;
  paymentCod?: number | string;
  removeProductFromCart: ({ uid }: { uid: string }) => void;
  updateProductAmount: ({ sku, uid, quantity }: UpdateProductAmountProps) => void;
  addProductToCart: ({ sku, quantity, option_uid, uid, category_id }: AddProductProps) => void;
  handleModalFlex?: (product: ProductProps) => void;
  handleModalInfos?: (product: ProductProps) => void;
}

interface PropsFlex extends Product {
  substTrib: TaxSubstitutionProps[];
}

interface PropsNoFlex extends Product {
  substTrib?: never;
}

export type Props = PropsFlex | PropsNoFlex;

const RenderProducts = ({
  product,
  substTrib,
  paymentCod,
  handleModalFlex,
  handleModalInfos,
  showFlex = true,
  showInfos = true,
  direction = 'grid',
}: Props) => {
  const { getLimit, cartDetails, getStockMsg } = useCart();
  const { paymentCode } = useGlobals();
  const { getDiscountProduct, discounts } = useDiscountFlex();
  const { selectedClient } = useClient();
  const { stockLeft } = useStock();
  const [isOpenedModal, openModal] = useState(false);
  const [openAttributesModal, setOpenAttributesModal] = useState(false);
  const [pack, setPackage] = useState(product.EMBALAGEM?.[0]?.SG_EMBALAGEM);
  const [packQuantity, setPackQuantity] = useState(
    product.EMBALAGEM?.[0]?.QTD_MULTIPLO_VENDA > 1
      ? product?.EMBALAGEM?.[0]?.QTD_MULTIPLO_VENDA
      : product?.EMBALAGEM?.[0]?.QTD_EMBALAGEM
  );

  const packing = useMemo(() => {
    return product.EMBALAGEM.find((p) => {
      if (pack === 'PK') {
        return pack === p?.SG_EMBALAGEM && packQuantity === p?.QTD_MULTIPLO_VENDA;
      }
      return pack === p?.SG_EMBALAGEM && packQuantity === p?.QTD_EMBALAGEM;
    });
  }, [product, pack, packQuantity]) as PackagingProductProps;

  const modePayment = useMemo(() => Number(paymentCode || paymentCod), [paymentCode, paymentCod]);

  const price = useMemo(() => {
    return (
      product.PRECO.find((p) => {
        if (pack === 'PK') {
          return p.SG_EMBALAGEM === pack && packQuantity === p.QTD_MULTIPLO_VENDA && p.NROCONDICAOPAGTO === modePayment;
        }
        return p.SG_EMBALAGEM === pack && p.QTD_EMBALAGEM === packQuantity && p.NROCONDICAOPAGTO === modePayment;
      }) || ({} as PriceProductProps)
    );
  }, [product, pack, modePayment, packQuantity]);

  const discountPrice = useMemo(() => {
    const emb = product.EMBALAGEM.find((i) => {
      if (pack === 'PK') {
        return i.SG_EMBALAGEM === pack && packQuantity === i?.QTD_MULTIPLO_VENDA;
      }
      return pack === i?.SG_EMBALAGEM && packQuantity === i?.QTD_EMBALAGEM;
    });
    return getDiscountProduct({
      package: pack,
      productId: product.COD_PRODUTO,
      quantity: emb?.QTD_MULTIPLO_VENDA && emb?.QTD_MULTIPLO_VENDA > 1 ? emb?.QTD_MULTIPLO_VENDA : emb?.QTD_EMBALAGEM || 0,
    })?.discountPrice?.[modePayment];
  }, [getDiscountProduct, pack, packQuantity, modePayment, product.COD_PRODUTO, product.EMBALAGEM]);

  const commissionFlag = useMemo(() => {
    if (product && pack) {
      const foundItem = product.EMBALAGEM.find((p) => {
        if (pack === 'PK') {
          return p.SG_EMBALAGEM === pack && p.QTD_MULTIPLO_VENDA === packQuantity;
        }
        return p.SG_EMBALAGEM === pack && p.QTD_EMBALAGEM === packQuantity;
      });
      return foundItem ? foundItem.SG_COMISSAO : null;
    }
    return null;
  }, [product, pack, packQuantity]);

  const paymentCodeDesc = useMemo(() => {
    if (paymentCode === 7) return '7';
    if (paymentCode === 14) return '14';
    if (paymentCode === 21) return '21';
    if (paymentCode === 28) return '28';
    if (paymentCode === 35) return '35';
    if (paymentCode === 114) return '7/14/21';
    if (paymentCode === 121) return '14/21/28';
    if (paymentCode === 128) return '21/28/35';
    if (paymentCode === 135) return '28/35/42';
    // if (paymentCode === 120) return "120" -> Cartão de crédito

    return `${paymentCode}`;
  }, [paymentCode]);

  const ean = useMemo(() => {
    return product.ATRIBUTOS.find((at) => at.ean_grp)?.ean_grp;
  }, [product]);

  const quantity = useMemo(() => {
    if (cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.length > 0) {
      return (
        cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.find((i) => {
          const [emb, qtd] = i?.option_uid?.split('_') as [PackagesTypesProps, string];

          if (packing?.QTD_MULTIPLO_VENDA > 1 && emb === 'PK') {
            return (
              i?.sku === product?.COD_PRODUTO && packing?.SG_EMBALAGEM === emb && qtd === packing?.QTD_MULTIPLO_VENDA?.toString()
            );
          }

          return i?.sku === product?.COD_PRODUTO && packing?.SG_EMBALAGEM === emb && qtd === packing?.QTD_EMBALAGEM?.toString();
        })?.quantity || 0
      );
    } else {
      return 0;
    }
  }, [
    cartDetails,
    selectedClient?.COD_CLIENTE,
    packing?.QTD_MULTIPLO_VENDA,
    packing?.SG_EMBALAGEM,
    packing?.QTD_EMBALAGEM,
    product?.COD_PRODUTO,
  ]);

  const hasMultiplePacking = useMemo(
    () =>
      product?.EMBALAGEM?.length > 1 &&
      cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.find((i: CartItems) => i?.sku === product?.COD_PRODUTO)
        ? cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.find((i: CartItems) => i?.sku === product?.COD_PRODUTO)
        : null,
    [product, selectedClient, cartDetails]
  );

  const hasExpiring = useMemo(() => {
    return getStockMsg(product?.COD_PRODUTO);
  }, [getStockMsg, product?.COD_PRODUTO])

  // Qtd total unificada do produto adicionadas ao carrinho, quando o produto possui múltiplas embalagens
  const totalQuantity = useMemo(() => {
    if (hasMultiplePacking) {
      let count = 0;
      cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.reduce((acc, currentValue) => {
        if (currentValue?.sku === product?.COD_PRODUTO) {
          const [, qtd] = currentValue?.option_uid?.split('_');

          const qty = Number(qtd);
          const val = currentValue?.quantity * qty;
          count = acc += val;
        }
        return acc;
      }, 0);
      return count;
    }
    return 0;
  }, [cartDetails, hasMultiplePacking, product?.COD_PRODUTO, selectedClient?.COD_CLIENTE]);

  // Qtd total adicionada ao carrinho, calculada com base em produtos com ou sem múltiplas embalagens
  const rawCurrentAddedQty = useMemo(() => {
    const maximum = packing?.QTD_MULTIPLO_VENDA > 1 && pack === 'PK';

    if (hasMultiplePacking && hasMultiplePacking?.sku) {
      return totalQuantity;
    } else if (!hasMultiplePacking?.sku && quantity) {
      return maximum ? packing?.QTD_MULTIPLO_VENDA * quantity : packing?.QTD_EMBALAGEM * quantity;
    }
    return 0;
  }, [hasMultiplePacking, pack, packing?.QTD_EMBALAGEM, packing?.QTD_MULTIPLO_VENDA, quantity, totalQuantity]);

  // Qtd de produto em estoque calculada com base na quantidade adicionada ao carrinho
  const rawQuantity = useMemo(() => {
    const limit = getLimit(product?.COD_PRODUTO);
    const stock = stockLeft?.[product?.COD_PRODUTO]?.stock_left;

    if (stockLeft?.[product?.COD_PRODUTO]) {
      if (stock && stock > 0) {
        if (rawCurrentAddedQty) {
          return stock <= rawCurrentAddedQty ? 0 : stock - rawCurrentAddedQty;
        }
        return stock;
      }
      return 0;
    }

    if (rawCurrentAddedQty) {
      return limit <= rawCurrentAddedQty ? 0 : limit - rawCurrentAddedQty;
    }
    return limit;
  }, [getLimit, product?.COD_PRODUTO, stockLeft, rawCurrentAddedQty]);

  // Controle de estoque geral
  const hasStock = useMemo(() => {
    const limit = getLimit(product?.COD_PRODUTO);
    const multiple = packing?.QTD_MULTIPLO_VENDA > 1;
    const stock = stockLeft?.[product?.COD_PRODUTO]?.stock_left;

    if (stockLeft?.[product?.COD_PRODUTO]) {
      if (stock && stock > 0) {
        if (rawCurrentAddedQty) {
          return stock > 0 && stock >= rawCurrentAddedQty;
        }
        return multiple ? stock > 0 && stock >= packing?.QTD_MULTIPLO_VENDA : stock > 0 && stock >= packing?.QTD_EMBALAGEM;
      }
      return null;
    }
    if (rawCurrentAddedQty) {
      return limit > 0 && limit >= rawCurrentAddedQty;
    }
    return multiple ? limit > 0 && limit >= packing?.QTD_MULTIPLO_VENDA : limit > 0 && limit >= packing?.QTD_EMBALAGEM;
  }, [getLimit, packing?.QTD_EMBALAGEM, packing?.QTD_MULTIPLO_VENDA, product?.COD_PRODUTO, rawCurrentAddedQty, stockLeft]);

  const showInfosFlex = useMemo(() => {
    if (product.EMBALAGEM) {
      const embalagem = product.EMBALAGEM.find((p) => {
        if (pack === 'PK') {
          return p.SG_EMBALAGEM === pack && packQuantity === p.QTD_MULTIPLO_VENDA;
        }
        return p.SG_EMBALAGEM === pack && packQuantity === p.QTD_EMBALAGEM;
      });
      if (embalagem) {
        return embalagem.PERC_MAX_FLEX !== 0 && embalagem.PERC_MIN_FLEX !== 0;
      }
    }
    return false;
  }, [pack, product.EMBALAGEM, packQuantity]);

  const unityPrice = useMemo(() => {
    if (price?.QTD_MULTIPLO_VENDA > 1) {
      return (discountPrice || price.VLR_FINAL) / price?.QTD_MULTIPLO_VENDA;
    } else {
      return (discountPrice || price.VLR_FINAL) / price?.QTD_EMBALAGEM;
    }
  }, [discountPrice, price?.QTD_EMBALAGEM, price?.QTD_MULTIPLO_VENDA, price.VLR_FINAL]);

  const uid = useMemo(() => {
    const emb = packing?.QTD_MULTIPLO_VENDA > 1 ? packing?.QTD_MULTIPLO_VENDA : packing?.QTD_EMBALAGEM;

    return `${product?.COD_PRODUTO}-${pack}-${emb}`;
  }, [product?.COD_PRODUTO, pack, packing]);

  const showSelo = useMemo(() => {
    return price?.TP_PROMO !== 'B'
  }, [price]);

  const showOldPrice = useMemo(() => {
    return price?.TP_PROMO !== 'B' && price?.VLR_PRECO > price.VLR_FINAL
  }, [price]);

  return (
    <div style={{ position: 'relative', height: 'inherit' }}>
      <S.ProductCard>
        <S.ProductContent direction={direction}>
          <S.Container direction={direction}>
            <S.PaddingImage direction={direction}>
              {showSelo && hasStock && <S.Promocao src={`${process.env.PUBLIC_URL}/promocao_vertical.png`} />}

              {showInfos && (
                <S.AddList
                  data-test={`open-modal-custom-attributes-${product.COD_PRODUTO}`}
                  onClick={() => (handleModalInfos ? handleModalInfos(product) : setOpenAttributesModal(true))}
                >
                  <S.BtnAddList grey={1} hasExpiring={!!hasExpiring}>
                    <RiInformationLine size={18} color={hasExpiring ? colors.brand.white : colors.brand.grafite} />
                  </S.BtnAddList>
                </S.AddList>
              )}

              {showInfos && !handleModalInfos && (
                <ModalCustomAttributes product={product} isOpen={openAttributesModal} openModal={setOpenAttributesModal} />
              )}

              {/* {showTime && (
                  <S.TimeContent>
                    <S.Time>
                      <IoTimeOutline color={colors.brand.white} size={13} />
                      <S.TimerText>03d | 09h | 39m | 46s</S.TimerText>
                    </S.Time>

                    <S.TimeLabel>Últimas unidades</S.TimeLabel>
                  </S.TimeContent>
                )} */}
              <S.ContentImage to={`/${product.COD_PRODUTO}`}>
                <S.ProductImage
                  alt={product.DESC_PRODUTO}
                  id={`product-image-${product.COD_PRODUTO}`}
                  src={`data:image/jpg;base64,${product.IMAGEM}`}
                />
              </S.ContentImage>
              {paymentCodeDesc && direction !== 'grid' && (
                <S.PaymentCondition>
                  <RiBarcodeBoxLine />
                  {paymentCodeDesc}
                </S.PaymentCondition>
              )}
            </S.PaddingImage>

            {paymentCodeDesc && direction === 'grid' && (
              <S.PaymentCondition>
                <RiBarcodeBoxLine />
                {paymentCodeDesc}
              </S.PaymentCondition>
            )}
            <S.Div>
              <S.ContentInfos direction={direction}>
                <S.Div>
                  <S.ContentChevron>
                    <S.LabelSelectEmbalagem>
                      <S.Line />

                      <S.SelectEmbalagem>Selecione a embalagem</S.SelectEmbalagem>

                      <S.Line />
                    </S.LabelSelectEmbalagem>

                    <FaChevronDown size={8} color={colors.grey2} />
                  </S.ContentChevron>

                  {/* {pack} */}
                  <Packaging
                    showQtd
                    pack={pack}
                    packQty={packQuantity}
                    product={product}
                    setPackage={setPackage}
                    setPackQuantity={setPackQuantity}
                  />
                  {hasStock && <Stock stockLeft={Math.floor(rawQuantity/packQuantity)} extraMargin />}
                </S.Div>

                <S.Div>
                  <S.Div>
                    <S.Row>
                      <S.Ean>EAN: {ean}</S.Ean>
                      <S.CommissionFlag>{commissionFlag}</S.CommissionFlag>
                    </S.Row>
                    <S.ProductLink to={`/${product.COD_PRODUTO}`}>
                      <S.DescProduct>{product.DESC_PRODUTO}</S.DescProduct>
                    </S.ProductLink>
                  </S.Div>

                  <S.PriceContent>
                    <S.ColPrice>
                      {showOldPrice && hasStock && <S.Underline>{formatCurrency({ value: price?.VLR_PRECO })}</S.Underline>}

                      {price?.VLR_FINAL && hasStock && (
                        <>
                          <S.Price>
                            {formatCurrency({
                              value: discountPrice || price.VLR_FINAL,
                            })}
                          </S.Price>
                          <StPrice
                            precoSt={price?.ST_VLR_FINAL}
                            extraPadding={true}
                            color={'#666'}
                            weight={800}
                            precoPauta={price?.VLR_PAUTA}
                            codTributacaoProduto={price?.COD_TRIBUTACAO_PRODUTO}
                            precoCalculado={price?.VLR_PRECO_CALCULADO}
                            cliente={selectedClient}
                            descontoFlex={
                              discounts?.[selectedClient?.COD_CLIENTE]?.[uid]?.percentual
                                ? discounts?.[selectedClient?.COD_CLIENTE]?.[uid]?.percentual
                                : 0
                            }
                          />
                        </>
                      )}

                      {(price?.QTD_EMBALAGEM || price?.QTD_MULTIPLO_VENDA) && price?.VLR_FINAL && hasStock && (
                        <S.UnitPrice>
                          {formatCurrency({
                            value: unityPrice,
                          })}
                          /un
                        </S.UnitPrice>
                      )}
                    </S.ColPrice>

                    {showFlex && showInfosFlex && hasStock && (
                      // <ToolTip
                      //   direction="top"
                      //   displayMode="click"
                      //   text='Serviço em manutenção'
                      // >
                        <S.ButtonFlex
                          $isdisabled={!(discountPrice || price?.VLR_FINAL) || !hasStock}
                          disabled={!(discountPrice || price?.VLR_FINAL) || !hasStock}
                          data-test={`button-flex-${product.COD_PRODUTO}`}
                          onClick={() => (handleModalFlex ? handleModalFlex(product) : openModal(true))}
                        >
                          <S.EditIcon size={14} />
                          Editar
                        </S.ButtonFlex>
                      // </ToolTip>
                    )}
                  </S.PriceContent>
                </S.Div>
              </S.ContentInfos>

              {direction === 'list' && (
                <AddToCart
                  pack={pack}
                  label="Adicionar"
                  product={product}
                  background="light"
                  packQty={packQuantity}
                  id={`${product.COD_PRODUTO}`}
                  unavailabel={(!discountPrice && !price?.VLR_FINAL) || !hasStock}
                />
              )}
            </S.Div>
          </S.Container>

          {direction === 'grid' && (
            <AddToCart
              pack={pack}
              packQty={packQuantity}
              label="Adicionar"
              background="light"
              product={product}
              // hasDiscount={!!discountPrice}
              id={`${product.COD_PRODUTO}`}
              unavailabel={(!discountPrice && !price?.VLR_FINAL) || !hasStock}
            />
          )}
        </S.ProductContent>
      </S.ProductCard>

      {showInfosFlex && !handleModalFlex && (
        <ModalFlexDiscount
          pack={pack}
          packQty={packQuantity}
          product={product}
          openModal={openModal}
          substTrib={substTrib || []}
          setPackage={setPackage}
          setPackQuantity={setPackQuantity}
          isOpenedModal={isOpenedModal}
        />
      )}
    </div>
  );
};

export default RenderProducts;
