import * as S from './Styled';
import { useCart, useClient } from '@/hooks';
import FastLinks from './fast-links';
import { useScrollPageEvent } from '@/talons';
import { useNavigate } from 'react-router-dom';
import FastCategories from './fast-categories';
import { useCalcPrice, useDbFunctions } from '@/talons';
import { BannerProps, RecomendacaoBigDataProps } from '@/types';
import { CATEGORY, SEARCH, PRODUCT_PAGE } from '@/constants/systemRoutes';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Slider, Search, Product, Loading, OfferDivider, ProductsSlider } from '@/components';

const mountBanner = (IMAGE: string, LINK?: string): BannerProps => ({
  IMAGE,
  LINK,
});

const qtd = 10;

const RenderProducts = () => {
  const navigate = useNavigate();
  const isLoading = useRef(false);
  const { getOnDB } = useDbFunctions();
  const { selectedClient } = useClient();
  const { page, setPage } = useScrollPageEvent();
  const [pageBuilder, setPageBuilder] = useState([] as BannerProps[]);
  const [currentClient, setCurrentClient] = useState(selectedClient.COD_CLIENTE);
  const { catalog, isLoadingCatalog, substTrib, getCatalog, reloadCatalog } = useCalcPrice();
  const { addProductToCart, updateProductAmount, removeProductFromCart, getLimit } = useCart();
  const [recomendacaoBigData, setRecomendacaoBigData] = useState([] as RecomendacaoBigDataProps[]);

  const listProducts = useMemo(() => {
    return catalog?.slice(0, page * qtd) || [];
  }, [catalog, page]);

  const productsIds = useMemo(() => {
    return catalog.map(i => `${i.COD_PRODUTO}`);
  }, [catalog])

  const enableLoadMore = useMemo(() => {
    return listProducts.length / qtd >= page;
  }, [listProducts, page]);

  const recommendations = useMemo(() => {
    const recommendationsIds = recomendacaoBigData.filter(r => r.COD_PESSOA === selectedClient.COD_CLIENTE).map((r) => r.COD_PRODUTO)
    return catalog.filter((p) => recommendationsIds.includes(Number(p.COD_PRODUTO)) && getLimit(p.COD_PRODUTO) && productsIds.includes(`${p.COD_PRODUTO}`));
  }, [catalog, recomendacaoBigData, productsIds, selectedClient, getLimit]);
  
  const getRecommendations = useCallback(async () => {
    const list = await getOnDB('recomendacaoBigData');
    setRecomendacaoBigData(list || []);
  }, [getOnDB]);

  const getPageBuilder = useCallback(async () => {
    if (pageBuilder.length) return

    const list = await getOnDB('pagebuilder');
    const banners = (list || [])
      .filter((l) => l.type === 'banner')
      .map((b) => {
        const isProduct = b.link_type === 'product';
        const page = isProduct ? PRODUCT_PAGE : CATEGORY;
        const [path] = page.link.split(':');
        const ID = b.list_sku.join('&');

        const link = `${path}:${ID}`;

        return { ...mountBanner(`data:image/jpg;base64,${b.base64Img}`, `${link}`), ID: b.ID };
      });

    setPageBuilder(banners);
  }, [pageBuilder, getOnDB]);

  const listBanners = useMemo(() => {
    return pageBuilder.reduce((acc, item) => {
      const [path] = item.LINK?.split(':') || []
      
      const skus = item.LINK?.split('&').filter(i => productsIds.includes(i))
      if (!skus?.length) return acc;

      const link = `${path}pagebuilder-${item.ID}`
      return [...acc, mountBanner(item.IMAGE, link)];
    }, [] as BannerProps[])
  }, [pageBuilder, productsIds]);

  const loadData = useCallback(async () => {
    const hasCatalog = !!catalog?.length;
    
    if ((hasCatalog && selectedClient.COD_CLIENTE === currentClient) || isLoading.current) return;

    isLoading.current = true;
    
    const loadCatalog = hasCatalog ? reloadCatalog : getCatalog;
    await loadCatalog(false, 1200, true);
    await getPageBuilder();
    await getRecommendations();

    isLoading.current = false;
    setCurrentClient(selectedClient.COD_CLIENTE);
  }, [
    getCatalog,
    reloadCatalog,
    getPageBuilder,
    setCurrentClient,
    getRecommendations,
    catalog,
    currentClient,
    selectedClient.COD_CLIENTE,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData, selectedClient.COD_CLIENTE]);

  const goToSearchPage = () => navigate(SEARCH.link);

  return (
    <S.Container className="products-container">
      <Search showUser value={''} showPaymentMode setFilter={() => {}} id="products-container" onFocus={goToSearchPage} />

      <S.ContainerSlider>
        <Slider dots autoplay isBanner canSwipe id="main-banner" slides={listBanners} />
      </S.ContainerSlider>

      <FastLinks />

      <FastCategories />

      {!!recommendations?.length && (
        <>
          <OfferDivider isinvert>
            <S.OfferText>
              Recomendamos para o seu <S.OfferSpan>Negócio</S.OfferSpan>
            </S.OfferText>
          </OfferDivider>

          <ProductsSlider
            dots
            id="promocoes"
            products={recommendations}
            addProductToCart={addProductToCart}
            removeProductFromCart={removeProductFromCart}
            updateProductAmount={updateProductAmount}
          />
        </>
      )}

      {/* <Slider isBanner id="banner-1" slides={[mountBanner(banner7)]} />
      <Slider isBanner id="banner-2" slides={[mountBanner(banner4)]} /> */}

      {/* <OfferDivider>
        <S.OfferText>Aproveite!!</S.OfferText>

        <S.Timer>
          <IoTimeOutline color={colors.brand.white} size={13} />
          <S.OfferText margin="0">03d | 09h | 39m | 46s</S.OfferText>
        </S.Timer>
      </OfferDivider>

      <ProductsSlider
        id="ofertas-da-semana"
        products={catalog?.slice(7, 13) || []}
        addProductToCart={addProductToCart}
        updateProductAmount={updateProductAmount}
        removeProductFromCart={removeProductFromCart}
      /> */}

      {/* <Slider isBanner id="banner-3" slides={[mountBanner(banner5)]} />
      <Slider isBanner id="banner-4" slides={[mountBanner(banner6, "/")]} /> */}

      <S.Div>
        <S.ProductsList>
          <S.ContentList>
            {listProducts.map((item: any, ind: number) => (
              <Product
                key={ind}
                substTrib={substTrib}
                product={{ ...item }}
                addProductToCart={addProductToCart}
                updateProductAmount={updateProductAmount}
                removeProductFromCart={removeProductFromCart}
              />
            ))}
          </S.ContentList>
        </S.ProductsList>

        {isLoadingCatalog.current && (
          <S.Loading>
            <Loading scale={1.8} />
          </S.Loading>
        )}

        {enableLoadMore && (
          <S.Center>
            <S.LoadMore onClick={() => setPage(page + 1)} data-test="products-page-load-more-button">
              Carregar mais
            </S.LoadMore>
          </S.Center>
        )}
      </S.Div>
    </S.Container>
  );
};

export default RenderProducts;
