import { colors } from "@/constants";
import { RiCheckboxBlankCircleLine, RiCheckboxCircleFill, RiCurrencyLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Container = styled.div`
  background-color: ${colors.brand.white};
  border-radius: 6px 6px 0 0;
  width: 100%;
  padding: 20px;
  position: sticky;
  /* bottom: 60px; */
  display: flex;
  flex-direction: column;
  gap: 12px;
  box-shadow: 0px -10px 30px 0px #0000001A;
  transition:bottom 1s linear;
`;

export const LinkButton = styled.button<{ $disabled?: boolean }>`
  border-radius: 8px;
  border: none;
  width: 100%;
  background-color: ${colors.brand.red};
  cursor: pointer;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  gap: 6px;
  ${props => props.$disabled && css`
    pointer-events: none;
    background-color: ${colors.gray.gray40};
  `}
`;

export const Loading = styled.div<{ $isLoading?: boolean }>`
  @keyframes load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  animation: ${i => i.$isLoading ? 'load' : ''} 1000ms infinite;
`

export const ButtonLabel = styled.div`
  color: ${colors.brand.white};
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 16px;
`;

export const Button = styled.div`
  width: 100%;
  border: none;
  padding: 0px;
  margin-top: 10px;
  align-items: center;
  display: inline-flex;
  background-color: ${colors.transparent};
  justify-content: space-between;
  border-bottom: 1px solid ${colors.background1};
  padding-bottom: 10px;
`;

export const PaymentCondition = styled.div`
  width: 100%;
  align-items: center;
  display: inline-flex;
`;

export const Icon = styled(RiCurrencyLine)`
margin-right: 10px;
`;

export const PaymentSelected = styled.p`
  color: ${colors.brand.grafite};
  margin: 0px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 14.06px;
  font-weight: 400;
`;

export const TotalsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CartQuantityLabel = styled.div`
  color: ${colors.brand.grafite};
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 12px;
`;

export const TotalLabel = styled.div`
  color: ${colors.gray.gray50};
  font-family: Roboto, sans-serif;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-weight: 400;
`;

export const Total = styled.div`
  font-weight: 700;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  color: ${colors.brand.grafite};
`;

export const ModalContent = styled.div`
  position: relative;
  /* bottom: 30px; */
`;

export const ModalTitle = styled.h5`
  top: 0px;
  font-size: 16px;
  font-weight: 500;
  padding-top: 30px;
  position: sticky;
  text-align: center;
  padding-bottom: 18px;
  background-color: ${colors.brand.white};
`;

export const ListBg = styled.div`
  gap: 1px;
  display: flex;
  padding: 1px 0px;
  margin-bottom: 10px;
  flex-direction: column;
  background-color: ${colors.grey4};
`;

export const PaymentCard = styled.button<{ $isactive: boolean }>`
  border: none;
  padding: 12px 16px;
  align-items: center;
  display: inline-flex;
  background-color: ${(i) =>
    i.$isactive ? colors.alert.green50 : colors.brand.white};
`;

export const CheckContent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding-right: 16px;
  margin-right: 16px;
  border-right: 1px solid ${colors.grey11};
`;

export const Check = styled.div``;

export const TitlePaymentForm = styled.p`
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  color: ${colors.brand.grafite};
`;

export const FinalPrice = styled.span`
  font-weight: 700;
  margin-left: 5px;
`;

export const BtnContainer = styled.div`
  bottom: 0px;
  bottom: 0px;
  position: sticky;
  padding: 26px 16px;
  background-color: ${colors.brand.white};
  box-shadow: 0px -4px 10px 0px ${colors.shadown6};
`;

export const CheckFill = styled(RiCheckboxCircleFill)``;

export const CheckBlank = styled(RiCheckboxBlankCircleLine)``;

export const ButtonModal = styled.button`
  border: none;
  width: 100%;
  padding: 18px 0px;
  border-radius: 8px;
  color: ${colors.brand.white};
  background-color: ${colors.green};
`;
