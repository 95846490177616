import * as S from "./styles";
import { Modal } from "@/components";
import { useClient, useGlobals, useStock } from "@/hooks";
import { setOnStorage, getOnStorage } from "@/shared";
import { useDownloadFiles } from "@/talons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

interface Props {
  children?: any;
  cbAction?: () => void;
  isLoading?: boolean | null;
}

const ModalSync = ({ children, isLoading, cbAction }: Props) => {
  const curLoading = useRef(false);
  const { setAllClients } = useClient();
  const { setLastSync, getDate } = useGlobals();
  const { triggerCompareStocks } = useStock();
  const [collapsed, setCollapsed] = useState(false);

  const {
    loading,
    hasError,
    fileName,
    datasets,
    isSuccess,
    isOpenedModal,

    setError,
    loadFiles,
    getLinks,
    openModalSync,

    noUpdate
  } = useDownloadFiles();

  useEffect(() => {
    if (!isOpenedModal) setCollapsed(false);
  }, [isOpenedModal, setCollapsed]);

  const handleLoadFiles = useCallback(async () => {
    openModalSync(true);
    setError(false);
    await new Promise((resolve) => setTimeout(() => resolve(true), 500));

    let data = undefined
    if (isLoading) data = await loadFiles();
    else data = await getLinks();
    const formattedDate = getDate();
    setLastSync(formattedDate);
    triggerCompareStocks();
    if (cbAction) cbAction();

    if (!getOnStorage('datasets')) {
      const datasets = getOnStorage('new_data_sets');
      setOnStorage("datasets", datasets || []);
    }

    if (data?.cliente) setAllClients(data?.cliente || [])
    
    return data
  }, [
    getDate,
    isLoading,
    cbAction,
    setError,
    loadFiles,
    setLastSync,
    getLinks,
    setAllClients,
    openModalSync,
    triggerCompareStocks,
  ]);

  useEffect(() => {
    if (!isLoading || curLoading.current) return;

    curLoading.current = true;
    handleLoadFiles();
  }, [isLoading, handleLoadFiles]);

  const currentLoading = useMemo(() => {
    return isLoading || loading;
  }, [loading, isLoading]);

  return (
    <>
      <S.ButtonSpan
        onClick={handleLoadFiles}
        data-test="modal-sync-button-open"
      >
        {children}
      </S.ButtonSpan>

      <Modal
        id="modal-sync"
        isOpen={isOpenedModal}
        isLoading={currentLoading}
        closeModal={openModalSync}
      >
        <S.Container>
          {/* <S.Loader
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
            onTouchStart={handleTouchStart}
          > */}
          {currentLoading && (
            <S.BigLoadContent>
              <S.BigLoad>
                <S.CentralLoad />
              </S.BigLoad>
            </S.BigLoadContent>
          )}

          {hasError && !currentLoading ? (
            <S.Error size={76} />
          ) : (
            isSuccess && <S.Success size={76} />
          )}

          <S.LoadingMsg>
            {hasError && !currentLoading
              ? "Falha na Sincronização"
              : isSuccess
                ? "Concluído"
                : "Aguarde"}
            ...
          </S.LoadingMsg>

          <S.Loader onClick={() => setCollapsed(!collapsed)}>
            <S.Message>
              {hasError && !currentLoading
                ? "Falha na sincronização dos dados, por favor tente novamente!"
                : noUpdate
                  ? "Não há atualizações disponíveis."
                  : isSuccess
                    ? "Sua sincronização já está concluída. Você já pode utilizar o app normalmente."
                    : "Estamos sincronizando seu aplicativo, logo você poderá navegar normalmente."}
            </S.Message>
          </S.Loader>

          {!hasError && !noUpdate && (
            <S.ProgressContent $collapsed={false}>
              <S.ProgressBar>
                <S.ProgressStyle id="progress-bar" />
              </S.ProgressBar>
            </S.ProgressContent>
          )}

          {currentLoading && (
            <>
              <S.FileLoading>{fileName}</S.FileLoading>

              <S.LittleLoading>
                <S.LittleLoadingPulse />
              </S.LittleLoading>
            </>
          )}

          {(isSuccess || (hasError && !currentLoading)) && (
            <>
              <S.SuccessText>
                {hasError ? "" : "Sincronização concluída!"}
              </S.SuccessText>

              <S.ButtonClose
                data-test="modal-sync-close-button"
                onClick={() => openModalSync(false)}
              >
                Fechar
              </S.ButtonClose>
            </>
          )}

          {datasets.map((i) => {
            return (
              <S.ProgressContent $collapsed={!collapsed} key={i.TIPO_ARQUIVO}>
                <S.ColLeft>
                  <S.ProgressName>
                    {i.TIPO_ARQUIVO?.replace(/([A-Z])|_/g, " $1")?.trim()}:
                  </S.ProgressName>
                </S.ColLeft>

                <S.ColRight>
                  <S.ProgressBar>
                    <S.ProgressStyle id={`${i.TIPO_ARQUIVO}-bar`} />

                    <S.ProgressText id={`${i.TIPO_ARQUIVO}-text`}>
                      0%
                    </S.ProgressText>
                  </S.ProgressBar>
                </S.ColRight>
              </S.ProgressContent>
            );
          })}
        </S.Container>
      </Modal>
    </>
  );
};

export default ModalSync;
