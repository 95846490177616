import * as S from './styles';
import { colors } from '@/constants';
import { formatCurrency } from '@/shared';
import { OrderItemsProps } from '@/talons/orderStatusPage/useOrderStatusPage';
import { RiCalendarScheduleLine } from "react-icons/ri";

interface CalendarProps {
  date: string;
  total: number;
  orders?: OrderItemsProps[];
}

const Calendar = ({ date, total }: CalendarProps) => {
  return (
    <S.CalendarContainer>
      <S.IconContainer>
        <RiCalendarScheduleLine size={21} color={colors.brand.grafite} />
      </S.IconContainer>

      <S.DateContainer>
        <S.Title>
          Pedido(s) feito(s) em:
        </S.Title>
        <S.Date>
          {date}
        </S.Date>
      </S.DateContainer>

      <S.DateContainer>
        <S.Title textalign="end">
          Total:
        </S.Title>
        
        <S.Total>
          {formatCurrency({ value: total })}
        </S.Total>
      </S.DateContainer>
    </S.CalendarContainer>
  )
}

export default Calendar;