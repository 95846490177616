import { colors } from "@/constants";
import styled, { css } from "styled-components";
import {
  RiAddFill,
  RiFlag2Line,
  RiBarcodeBoxLine,
  RiArrowDownSFill,
  RiProhibitedLine,
  RiCheckboxCircleFill,
  RiCalendarScheduleLine,
  RiCheckboxBlankCircleLine,
} from "react-icons/ri";

const types = {
  'danger' : colors.alert.red50,
  'default': colors.brand.white,
  'success' : colors.alert.green50,
  'warning': colors.alert.orange100,
}

const contentsColor = {
  'danger' : colors.alert.red100,
  'default': colors.grey15,
  'success' : colors.alert.green100,
  'warning': colors.alert.orange70,
}

const saldoColor = {
  'danger' : colors.alert.red50,
  'default': colors.gray.gray10,
  'success' : colors.alert.green200,
  'warning': colors.alert.orange50,
}

const lastPurchase = {
  'danger' : colors.alert.red50,
  'default': colors.grey15,
  'success' : colors.alert.green100,
  'warning': colors.alert.orange50,
}

const borderColor = {
  'danger' : colors.alert.red200,
  'default': colors.grey15,
  'success' : colors.alert.green100,
  'warning': colors.alert.orange300,
}

const textColors = {
  'danger' : colors.alert.red200,
  'default': colors.gray.gray50,
  'success' : colors.gray.gray50,
  'warning': colors.alert.orange300,
}

export type CardColor = 'success' | 'danger' | 'warning' | 'default'

export const Card = styled.div<{ $ischecked?: boolean, themeColor?: CardColor }>`
  position: relative;
  transition: all 0.5s;
  border-radius: 6px;
  margin: 0px 10px 15px 10px;
  box-shadow: 0px 4px 10px 0px #0000001A;
  background-color: ${({ themeColor }) => types[themeColor || 'default']};
  border: ${(i) => (i.$ischecked ? 2 : 0)}px solid ${(i) => (i.$ischecked ? colors.brand.grafite : colors.brand.white)};
`;

export const OpenDetails = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: ${colors.brand.grafite};
`

export const ContentInfos = styled.div<{$space: boolean}>`
  gap: 16px;
  width: 100%;
  padding: 16px;
  margin-bottom: 10px;
  padding-bottom: ${i => i.$space ? 0 : 0}px;
  display: inline-flex;
`;

export const CheckContent = styled.div``;
export const CheboxContent = styled.div<{ $isdisabled: boolean }>`
  opacity: ${(i) => (i.$isdisabled ? 0.2 : 1)};
`;

export const CheckboxCircle = styled(RiCheckboxCircleFill)`
  color: ${colors.alert.green300};
`;

export const ProhibitedIcon = styled(RiProhibitedLine)`
  color: ${colors.brand.white};
`;

export const CheckboxBlankCircle = styled(RiCheckboxBlankCircleLine)`
  color: ${colors.gray.gray40};
`;

export const InfosContent = styled.div`
  width: 100%;
`;

export const Row = styled.div<{ $centered?: boolean; $between?: boolean; marginTop?: number }>`
  gap: 12px;
  width: 100%;
  margin-top: ${(i) => (i.marginTop || 0)}px;
  align-items: ${(i) => (i.$centered ? "center" : "start")};
  justify-content: ${(i) => (i.$between ? "space-between" : "end")};
  display: inline-flex;
`;

export const Column = styled.div``;

export const AddresContent = styled.div<{ $isdisabled?: boolean }>`
  gap: 16px;
  flex-direction: row;
  display: inline-flex;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid ${colors.gray.gray10};
  opacity: ${(i) => (i.$isdisabled ? 0.5 : 1)};
`;

export const Cycle = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
`

export const CardTitle = styled.h6`
  font-size: 12px;
  text-align: left;
  font-weight: 500;
  margin-bottom: 12px;
  color: ${colors.gray.gray50};
`
export const Label = styled.span<{ weight?: 500 | 800; marginBottom?: number }>`
  font-size: 12px;
  text-align: left;
  color: ${colors.gray.gray50};
  font-weight: ${i => i.weight ?? 800};
  margin-bottom: ${i => i.marginBottom ?? 0}px;
`

export const SubLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 4px;
  color: ${colors.gray.gray40};
`

export const Separator = styled.div`
  height: inherit;
  width: 1px;
  background-color: ${colors.gray.gray20};
`
export const Positivacao = styled.div`
display: flex;
width: 100%;
flex-direction: column;
`

export const CycleContent = styled.div<{ $isdisabled?: boolean }>`
  gap: 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  opacity: ${(i) => (i.$isdisabled ? 0.5 : 1)};
`

export const Phone = styled.span`
  font-weight: 700;
`

export const CEP = styled.span`
  font-weight: 700;
`

export const LastPurchase = styled.p`
  font-weight: 500;
  font-size: 11px;
  color: ${colors.gray.gray50};
`

export const LastPurchaseContent = styled.div<{ themeColor?: CardColor; $isdisabled?: boolean }>`
  background-color: ${({ themeColor }) => lastPurchase[themeColor || 'default']};
  border: 1px solid ${({ themeColor }) => borderColor[themeColor || 'default']};
  border-radius: 6px;
  margin-bottom: 12px;
  padding: 3px;
  display: inline-flex;
  width: 100%;
  gap: 7px;
  justify-content: center;
  align-items: center;
  opacity: ${(i) => (i.$isdisabled ? 0.5 : 1)};
  p {
    color: ${({ themeColor }) => textColors[themeColor || 'default']}
  }
  svg {
    fill: ${({ themeColor }) => textColors[themeColor || 'default']}
  }
`

export const SpanContent = styled.span`
  flex-wrap: nowrap;
  float: right;
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
`

export const Address = styled.p`
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  color: ${colors.gray.gray50};
`;

export const MapContent = styled.span<{ themeColor?: CardColor }>`
  width: 32px;
  height: 32px;
  padding: 7px;
  display: grid;
  border-radius: 50%;
  place-items: center;
  background-color: ${({ themeColor }) => contentsColor[themeColor || 'default']};
  svg {
    fill: ${({ themeColor }) => textColors[themeColor || 'default']}
  }
`

export const ColumnRight = styled.div`
  flex: auto;
  text-align: right;
  margin-bottom: 12px;
`;

export const CompanieName = styled.p`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 8px;
  line-height: 22.4px;
  color: ${colors.gray.gray50};
`;

export const DiscountFlex = styled.span`
  width: fit-content;
  padding: 4px;
  border-radius: 6px;
  background-color: #f2f2f2;
  font-size: 11px;
  font-weight: 700;
  margin-left: 10px;
  color: #666666;
`;

export const CNPJLabel = styled.p<{ $isdisabled?: boolean }>`
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 10px;
  color: ${colors.gray.gray40};
  opacity: ${(i) => (i.$isdisabled ? 0.5 : 1)};
`;

export const ContentAlert = styled.div<{ $showBorder?: boolean; themeColor?: CardColor }>`
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  transition: all 0.25s;
  background-color: ${({ themeColor }) => contentsColor[themeColor || 'default']};
  ${(i) =>
    i.$showBorder &&
    css`
      margin-top: 10px;
      border-top: 1px dashed ${colors.gray.gray10};
    `}
`;

export const AlertMessage = styled.p<{ type: "warning" | "danger" }>`
  gap: 7px;
  width: 100%;
  font-size: 11px;
  margin-top: 10px;
  font-weight: 400;
  text-align: center;
  padding: 4px 12px;
  border-radius: 6px;
  display: inline-flex;
  justify-content: center;
  color: ${(i) =>
    i.type === "danger" ? colors.alert.red200 : colors.alert.orange300};
  border: 1px solid
    ${(i) =>
      i.type === "danger" ? colors.alert.red200 : colors.alert.orange300};
  background-color: ${(i) =>
    i.type === "danger" ? colors.alert.red50 : colors.alert.orange50};
`;

export const CalendarIcon = styled(RiCalendarScheduleLine)`
  color: ${colors.alert.red200};
`;

export const FlagIcon = styled(RiFlag2Line)`
  color: ${colors.alert.orange300};
`;

export const AlertMessageBold = styled.span`
  font-weight: 700 !important;
`;

export const FantasyName = styled.span<{ $isdisabled?: boolean }>`
  opacity: ${(i) => (i.$isdisabled ? 0.3 : 1)};
`;

export const NumberCompanie = styled.span<{ $isActive?: boolean, themeColor?: CardColor }>`
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  margin-right: 5px;
  border-radius: 4px;
  padding: 4px 8px 4px 8px;
  color: ${colors.gray.gray60};
  background-color: ${(i) => i.themeColor === 'default' ? i.$isActive ? colors.alert.green200 : colors.gray.gray10 : contentsColor[i.themeColor || 'default']};
`;

export const ArrowIcon = styled(RiArrowDownSFill)<{ $isOpened?: boolean }>`
  transition: all 0.4s;
  transform-origin: center;
  color: ${colors.gray.gray50};
  transform: rotate(${(i) => (i.$isOpened ? 180 : 0)}deg);
`;

export const BarIcon = styled(RiBarcodeBoxLine)`
  color: ${colors.gray.gray50};
`;

export const BarNotification = styled.span`
  top: -3px;
  right: -5px;
  font-size: 9px;
  min-width: 15px;
  padding: 2px 4px;
  position: absolute;
  border-radius: 50px;
  color: ${colors.brand.white};
  background: ${colors.alert.red200};
`;

export const ContentBar = styled.div`
  position: relative;
  margin-top: 2px;
`;

export const ContentProhibited = styled.div`
  padding: 4px 4px 1px 4px;
  width: fit-content;
  border-radius: 4px;
  background-color: ${colors.alert.red200};
`;

export const ArrowContent = styled.div<{ $isdisabled?: boolean, $isOpened?: boolean }>`
  gap: 8px;
  width: 100%;
  cursor: pointer;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin-top: ${i => i.$isOpened ? 16 : 0 }px;
  opacity: ${(i) => (i.$isdisabled ? 0.2 : 1)};
`;

export const ProgressContent = styled.div`
  gap: 5px;
  display: inline-flex;
  width: -webkit-fill-available;
`;

export const MetaLabel = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${colors.gray.gray40};
`;

export const Progress = styled.div`
  width: 50px;
  height: 10px;
  position: relative;
  border-radius: 6px;
  border: 1px solid #ccc;
  background-color: #f0f0ee;
`;

export const BarProgress = styled.div`
  width: 50%;
  height: 100%;
  border-radius: 6px;
  background-color: ${colors.alert.green300};
`;

export const ProgressIcon = styled.div``;

export const AccordionInfos = styled.div<{ themeColor?: CardColor }>`
  width: 100%;
  padding: 8px 0px;
  display: inline-flex;
  justify-content: space-evenly;
  background-color: ${({ themeColor }) => saldoColor[themeColor || 'default']};
`;

export const AccordionContent = styled.div`
  width: 100%;
  margin-top: 10px;
  overflow: hidden;
  border-radius: 6px;
`;

export const AccordionDataInfo = styled.div`
  padding: 0px 16px;
  width: 100%;
  border-right: 1px solid ${colors.grey11};
  &:last-child {
    border-right: none;
  }
`;

export const AccordionLabel = styled.p`
  font-size: 11px;
  font-weight: 400;
  text-align: left;
  color: ${colors.gray.gray60};
`;

export const AccordionLimit = styled.p`
  font-size: 11px;
  text-align: left;
  font-weight: 700;
  color: ${colors.gray.gray60};
`;

export const RequestLimit = styled.button`
  gap: 8px;
  width: 100%;
  border: none;
  padding: 8px 0px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  color: ${colors.brand.white};
  background-color: ${colors.alert.green300};
`;

export const IconPlus = styled(RiAddFill)`
  color: ${colors.alert.green50};
`;
