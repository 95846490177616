import "./styles.css";
import * as S from "./styles";
import { Loading, Summary } from "@/components";
import { CartItems } from "@/hooks/CartContext";
import CartMenu from "@/components/cartMenu/CartMenu";
import { useCalcPrice, useDbFunctions } from "@/talons";
import EmptyCart from "@/components/emptyCart/EmptyCart";
import { useCart, useClient, useGlobals } from "@/hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { ProductProps, CategoryProps, PriceProductProps } from "@/types";

interface Product extends ProductProps {
  sku: number;
  uid: string;
  packing: string;
  quantity: number;
  option_uid: string;
  category_id: number;
  currentPrice: PriceProductProps;
}
interface CartProductItem {
  item: CartItems;
  product: ProductProps | undefined;
  category: CategoryProps | undefined;
}

const findPrice = (
  price: PriceProductProps[],
  option_uid: string,
  paymentCondition: number,
) => {
  const [packing, quantity] = option_uid?.split("_");
  const correctPrice = price.find((p) => {
    return (
      p.SG_EMBALAGEM === packing &&
      p.QTD_EMBALAGEM?.toString() === quantity &&
      p.NROCONDICAOPAGTO === paymentCondition
    );
  });
  return (
    correctPrice || {
      VLR_PRECO: 0,
    }
  );
};

const RenderProducts = () => {
  const { paymentCode } = useGlobals();
  const { getOnDB } = useDbFunctions();
  const { selectedClient } = useClient();
  const { handlePrices } = useCalcPrice();
  
  const [total] = useState(0);
  const isLoading = useRef(false);
  const [categoriesList, setCategoriesList] = useState([] as string[]);
  const [productsList, setProductsList] = useState([] as CartProductItem[]);
  const [productMap, setProductMap] = useState({} as Record<number, ProductProps>);
  const [categoriesL, setCategoriesL] = useState({} as Record<number, CategoryProps>);
  const [categoryMap, setCategoryMap] = useState({} as Map<string, { productList: Product[]; quantity: number; category_id: number }>);

  const {
    cartLoading,
    cartDetails,
    setCartLoading,
    setIsSearching,
    addProductToCart,
    updateProductAmount,
    removeProductFromCart,
    handleRemoveItems: clearCart,
  } = useCart();

  const getCatalog = useCallback(async () => {
    if (isLoading.current) return;
    setCartLoading(true);
    const categoryMap = new Map();
    const categoriesList = [] as string[];

    const codLoja = selectedClient.COD_LOJA;
    const tabVenda = selectedClient.COD_TABELA_VENDA;
    const condicoes = selectedClient.COD_PAGAMENTO.map((i) => i.cod_condicao);

    const catalog = await getOnDB("dicionario_produtos");
    const categories = await getOnDB("dicionario_categorias");

    const listSubstTrib = await getOnDB("substituicaotributaria").then(list => list);
    const parametroPrecoBase = await getOnDB("parametroPrecoBase").then(
      (list) => {
        return list?.filter(
          (p) =>
            `${p.NROTABVENDA}` === `${tabVenda}` &&
            condicoes.includes(p.NROCONDICAOPAGTO) &&
            codLoja === p.COD_LOJA
        );
      }
    );

    const percentualFornecedor = await getOnDB("percentualFornecedor").then(
      (list) => {
        return list?.filter((p) => `${p.NROTABVENDA}` === `${tabVenda}`);
      }
    );
    
    const parametroPrecoPromocao = await getOnDB("precoPromocaoCliente")
  
    const items = cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.map(
      (item) => {
        const category = categories?.[item?.category_id];
        const product = catalog?.[item?.sku];

        const price = handlePrices(
          condicoes,
          product,
          parametroPrecoBase,
          percentualFornecedor,
          listSubstTrib,
          parametroPrecoPromocao
        );

        product.PRECO = price;

        return { item, category, product };
      }
    )

    items?.forEach((item) => {
      if (!categoryMap.has(item?.category?.DE_CATEGORIA)) {
        categoryMap.set(item?.category?.DE_CATEGORIA, {
          productList: [
            {
              ...item?.item,
              ...item?.product,
              category: item?.category,
              packing: item?.item?.option_uid,
              currentPrice: item?.product?.PRECO && findPrice(item?.product?.PRECO, item?.item?.option_uid, paymentCode),
            },
          ],
          quantity: item?.item?.quantity,
          category_id: item?.category?.COD_CATEGORIA,
        });
        if (item?.category?.DE_CATEGORIA) {
          categoriesList.push(item?.category?.DE_CATEGORIA);
        }
      } else if (categoryMap.has(item?.category?.DE_CATEGORIA)) {
        const value = categoryMap?.get(item?.category?.DE_CATEGORIA);
        const newValue = [
          ...value?.productList,
          {
            ...item?.item,
            ...item?.product,
            category: item?.category,
            packing: item?.item?.option_uid,
            currentPrice: item?.product?.PRECO && findPrice(item?.product?.PRECO, item?.item?.option_uid, paymentCode),
          },
        ];
        categoryMap.set(item?.category?.DE_CATEGORIA, {
          productList: newValue,
          category_id: item?.category?.COD_CATEGORIA,
          quantity: value.quantity + item?.item?.quantity,
        });
      }
    });

    setProductsList(items);
    setCategoriesList(categoriesList);
    setCategoryMap(categoryMap);
    setProductMap(catalog);
    setCategoriesL(categories);
    setCartLoading(false);
  }, [
    cartDetails,
    paymentCode,
    selectedClient,
    getOnDB,
    handlePrices,
    setCartLoading,
  ]);

  useEffect(() => {
    if (cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.length > 0 && !productsList?.length) {
      getCatalog();
    }
  }, [selectedClient, cartDetails, getCatalog, productsList]);

  if (cartLoading) return (
    <S.PageContainer>
      <S.LoaderContainer>
        <Loading scale={1.5} />
      </S.LoaderContainer>
    </S.PageContainer>
  );

  if (!cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.length && !cartLoading) {
    return <EmptyCart />;
  }

  return (
    <S.PageContainer>
      <CartMenu
        handleCreateListFromCart={() => null}
        cartItemsCount={cartDetails?.[selectedClient?.COD_CLIENTE]?.quantity}
        handleRemoveAll={clearCart}
        setIsSearching={setIsSearching}
      />
      <Summary
        totalPriceSt={""}
        items={productsList}
        productMap={productMap}
        subtotal={`R$ ${total}`}
        categories={categoriesL}
        categoryMap={categoryMap}
        categoriesList={categoriesList}
        cartTotalQuantity={cartDetails?.[selectedClient?.COD_CLIENTE]?.quantity}
        addProductToCart={addProductToCart}
        updateProductAmount={updateProductAmount}
        removeProductFromCart={removeProductFromCart}
      />
    </S.PageContainer>
  );
};

export default RenderProducts;
