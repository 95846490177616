import styled, { css } from "styled-components";
import { colors, device } from "@/constants";

export const Container = styled.div`
  padding: 10px 10px 30px 10px;
`;

export const BoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 16px;
`;

export const Indicator = styled.div<{ row?: boolean }>`
  background-color: transparent;
  border-radius: 8px;
  border: solid 1px ${colors.brand.white};
  width: ${({ row }) => row ? '100%' : 'calc(50% - 7px)'};
`;

export const BoxHeader = styled.div`
  display: flex;
  justify-content: center;
  padding: 11px 0;
`;

export const Title = styled.span`
  color: ${colors.brand.grafite};
  font-size: 10px;
  font-weight: 700;
  line-height: 11.72px;
  text-transform: uppercase;
`;

export const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;

  background-color: ${colors.brand.white};
  padding: 16px 14.05px;
`;

export const IndicatorResume = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const Icon = styled.div<{ typeColor?: any }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${({ typeColor }) => typeColor.background};

  svg{
    font-size: 16px;
    width: 16px;
    height: 16px;
    color: ${({ typeColor }) => typeColor.color};
  }
`;

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  gap: 4px;
  flex-grow: 1;

  &:last-of-type{
    align-items: flex-end;
  }
`;

export const Row = styled.div`
  display: flex;

  flex-wrap: wrap;
  flex-grow: 1;
`;

export const Field = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 4px;
  color: ${colors.gray.gray40};
  font-size: 12px;
  line-height: 14.08px;
  font-weight: 500;

  div{
    display: flex;
    align-items: center;
  }
`;

export const Value = styled.div`
  color: ${colors.brand.grafite};
  font-size: 14px;
  line-height: 16.41px;
  font-weight: 700;
  
`;

export const IndicatorsDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  justify-content: space-between;
  padding-top: 17px;
  border-top: solid 1px ${colors.grey11};
`;

export const DetailsData = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;

  &:last-child{
    align-items: flex-end;
  }
`;

export const PercentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

export const Percent = styled.span<{ color?: any }>`
  background-color: ${({ color }) => color?.background};
  color: ${({ color }) => color?.color};
  font-size: 10px;
  font-weight: 700;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 2px 6px;
  border-radius: 10px;

  svg{
    font-size: 12px;
  }
`;

export const CustomRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
`;

export const Percentage = styled.span<{ color?: any }>`
  color: ${colors.gray.gray40};
  font-size: 12px;
  font-weight: 400;
`;

export const MetricasWrapper = styled.div<{ isVisible: boolean, height: number }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: ${({ isVisible, height }) => (isVisible ? `calc(${height} + 20px)` : "0px")};
  min-height: auto;
  margin-top: 0px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, padding-top 0.3s ease-in-out;

  
`;

export const Resume = styled.div`
  width: 100%;
  background-color: ${colors.brand.white};
  border-radius: 6px 6px 0 0;
  padding: 15px 25px;
`;

export const ResumeWrapper = styled.div`
  padding: 10px 10px;
`;

export const ResumeTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ResumeTitle = styled.span`
  color: ${colors.brand.grafite};
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
  text-align: center;
  margin-bottom: 10px;

  b:first-of-type{
    color: ${colors.alert.blue200};
  }

  b:last-of-type{
    color: ${colors.alert.green300};
  }
`;

export const ResumeDetailed = styled.div`
  color: ${colors.gray.gray40};
  font-size: 13px;
  font-weight: 400;
  line-height: 15.28px;
  text-align: center;
`;

export const ResumeDetails = styled.div`
  display: flex;
  flex-direction: row;  
  justify-content: center;
  gap: 20px;

  margin-top: 32px;
`;

export const MetaAtingida = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.13px;
  text-align: center;
  color: ${colors.brand.grafite};
`;

export const MetaFaltante = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

`;

export const CalcMetaFaltante = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 82px;
  height: 24px;
  padding: 4px 8px;
  border-radius: 16px;
  background-color: ${colors.brand.red};
  box-shadow: 0px 4px 4px 0px #00000040;

  font-size: 12px;
  font-weight: 700;
  line-height: 14.08px;
  color: ${colors.brand.white};
`;

export const MetaFaltanteDetails = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 10px;

  margin-top: 18px;

  span{
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: ${colors.brand.grafite};

    small{
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      margin-left: 4px;
      margin-right: 4px;
      padding: 2px;
      color: ${colors.alert.red200};
      background-color: ${colors.alert.red50};
    }
  }

`;

export const IconFunds = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;
  background-color: ${colors.alert.red200};
  border-radius: 6px;

  svg{
    color: ${colors.brand.white};
    font-size: 18px;
  }
`;

export const DaysControl = styled.div<{ actualDays: number, remainingDays: number }>`
  display: grid;
  grid-template-columns: ${({ actualDays, remainingDays }) => {
    const totalDays = actualDays + remainingDays;
    const actualPercentage = (actualDays / totalDays) * 100;
    const remainingPercentage = (remainingDays / totalDays) * 100;
    return `${actualPercentage}% ${remainingPercentage}%`;
  }};
  min-height: 24px;
  overflow: hidden;
  border-radius: 0px 0px 6px 6px;

  div{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.08px;
  }
`;

export const RemainingDays = styled.div`
  background-color: ${colors.alert.red100};
  color: ${colors.alert.red200};
  text-align: center;
`;

export const ActualDay = styled.div`
  background-color: ${colors.alert.red200}; 
  color: ${colors.brand.white};
`;

export const DayRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -14px;
  padding: 2px 20px;

  font-size: 12px !important;
`;

export const Date = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding-top: 16px;
  color: ${colors.brand.grafite};
  font-size: 12px;
  line-height: 14.08px;
  font-weight: 700;

  svg{
    width: 16px;
    height: 16px;
  }
`;