import { useCallback, useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

import {
  useDiscountAmountFlex,
  useGlobals,
  useStock,
  useUserContext,
  useCart,
  useCartTotals,
  useClient,
  useDiscountFlex,
  useNetworkStatusContext,
  useFetchAxios,
} from "@/hooks";
import { ProductProps } from "@/types";
import { setOnStorage, getOnStorage } from "@/shared";
import { useCalcPrice, useDbFunctions } from "@/talons";
import { HandlePrices } from "@/shared/calculatePrices";

import { CartItems } from "@/hooks/CartContext";
import cartItemsMock from "@/mocks/cartItemsMocks";
import shippingMethod from "@/mocks/shippingMethodMocks";
import { StockProps } from "@/hooks/StockContext";
import { OrderPayloadProps } from "./type";


interface CartProductItem {
  item: CartItems;
  product: ProductProps | undefined;
}

export const useCheckoutPage = () => {
  const navigate = useNavigate();
  const { getOnDB } = useDbFunctions();
  const { axiosCtx } = useFetchAxios();
  const { cartTotals, listaTributacao } = useCartTotals();
  const { handlePrices } = useCalcPrice();
  const { handleUpdateStock, stockLeft } = useStock();
  const { discountAmountClient } = useDiscountAmountFlex();
  const { connectivityStatus } = useNetworkStatusContext();
  const { selectedClientAddress, selectedClient, calculateDeliveryDate, setJanelaEntregaData } = useClient();
  const { cartDetails, handleRemoveItems, getLimit } = useCart();
  const { clearClientDiscount, getDiscountProduct } = useDiscountFlex();
  const { setRcaSaldoFlex: setFlex, rcaSaldoFlex } = useUserContext();
  const { paymentCode, setPaymentCode, listPaymentCode, setCanAccessSuccess } = useGlobals();

  const [, setSucess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([] as CartProductItem[]);

  const [erpNumber, setErpNumber] = useState('');

  const creditLimit = selectedClient?.SALDO_DISPONIVEL; // Limite de crédito do cliente

  const getItems = useCallback(async () => {
    const codLoja = selectedClient?.COD_LOJA;
    const tabVenda = selectedClient?.COD_TABELA_VENDA;
    const condicoes = selectedClient?.COD_PAGAMENTO?.map((i) => i.cod_condicao);

    const catalog = await getOnDB("dicionario_produtos");

    const listSubstTrib = await getOnDB("substituicaotributaria").then(list => list);


    const parametroPrecoBase = await getOnDB("parametroPrecoBase").then(
      (list) => {
        return list?.filter(
          (p) =>
            `${p.NROTABVENDA}` === `${tabVenda}` &&
            condicoes.includes(p.NROCONDICAOPAGTO) &&
            codLoja === p.COD_LOJA
        );
      }
    );
    const percentualFornecedor = await getOnDB("percentualFornecedor").then(
      (list) => {
        return list?.filter((p) => `${p.NROTABVENDA}` === `${tabVenda}`);
      }
    );

    const parametroPrecoPromocao = await getOnDB("precoPromocaoCliente")

    const items = cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.map((item) => {
      const product = catalog?.[item?.sku];
      // list.map((product) => {
      product.PRECO = handlePrices(
        condicoes,
        product,
        parametroPrecoBase,
        percentualFornecedor,
        listSubstTrib,
        parametroPrecoPromocao,
      );
      
      return { item, product };
    });
    setProductList(items);

    return items;
  }, [cartDetails, getOnDB, handlePrices, selectedClient?.COD_CLIENTE, selectedClient?.COD_LOJA, selectedClient?.COD_PAGAMENTO, selectedClient?.COD_TABELA_VENDA]);

  useEffect(() => {
    if (cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.length > 0 && !productList?.length) {
      getItems();
    }
  }, [selectedClient, cartDetails, getItems, productList]);

  const conditions = useMemo(() => {
    if (listPaymentCode) {
      return listPaymentCode;
    }

    return null;
  }, [listPaymentCode]);

  const shippingData = useMemo(() => {
    if (selectedClientAddress && shippingMethod) {
      const deliveryDate = calculateDeliveryDate(); // Calcula a data de entrega
      if (deliveryDate) setJanelaEntregaData(deliveryDate);
      return [
        { dataType: "address", ...selectedClientAddress },
        { dataType: "shipping", deliveryDate, ...shippingMethod },
      ];
    }
    return [];
  }, [selectedClientAddress, calculateDeliveryDate, setJanelaEntregaData]);

  const cartItems = useMemo(() => {
    return cartItemsMock || null;
  }, []);

  // Fechamento de pedido
  const generateOrderNumber = (seqpessoa: any) => {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const year = now.getFullYear();
    const formattedDate = `${day}${month}${year}`;

    const formattedHour = String(now.getHours()).padStart(2, "0");
    const formattedMinute = String(now.getMinutes()).padStart(2, "0");
    const formattedSecond = String(now.getSeconds()).padStart(2, "0");

    return `${seqpessoa +
      formattedDate +
      formattedHour +
      formattedMinute +
      formattedSecond
      }`;
  };

  const handleControlStock = useCallback(() => {
    productList.forEach(item => {
      // eslint-disable-next-line
      const [_, qtd] = item?.item?.option_uid?.split("_");
      const fullStock = getLimit(item?.item?.sku);
      const hasRegistered = stockLeft?.[item?.item?.sku] as StockProps;
      const stock = stockLeft?.[item?.item?.sku]?.stock_left as number;

      handleUpdateStock({
        product_code: item?.item?.sku,
        stock: {
          stock_left:
            hasRegistered && stock === item?.item?.quantity * Number(qtd) ?
              0 :
              hasRegistered && stock > 0 ?
                stock - item?.item?.quantity * Number(qtd) :
                !hasRegistered && fullStock === item?.item?.quantity * Number(qtd) ?
                  0 :
                  (fullStock - item?.item?.quantity * Number(qtd))
        }
      });
    })
  }, [productList, getLimit, stockLeft, handleUpdateStock]);

  const erpOrderNumber = useMemo(() => {
    return erpNumber || null;
  }, [erpNumber]);

  const orderPayload = useMemo(() => {
    const now = new Date();
    if (productList) {
      const items = productList?.map((item: CartProductItem) => {

        const [pack, qtd] = item?.item?.option_uid?.split("_");
        const qty = Number(qtd);
        const preco = item?.product?.PRECO?.find(i => {
          return pack === "PK" ? 
            i?.SG_EMBALAGEM === pack && i?.QTD_MULTIPLO_VENDA === qty && i?.NROCONDICAOPAGTO === paymentCode :
            i?.SG_EMBALAGEM === pack && i?.NROCONDICAOPAGTO === paymentCode && i?.QTD_EMBALAGEM === qty;
        });
        
        // Todos as embalagens possuem o mesmo cod_segmento, por este motivo pegamos a posição 0
        const cod_segmento = item?.product?.EMBALAGEM[0].COD_SEGMENTO;
        const integrationPrice = Number(preco?.VLR_PRECO_CALCULADO) / (Number(preco?.QTD_MULTIPLO_VENDA) || 1);

        const flexIntegrationPrice = getDiscountProduct({
          productId: item?.item?.sku,
          package: pack,
          quantity: qty,
        });

        const percentualFlex = flexIntegrationPrice ? (Number(flexIntegrationPrice?.percentual).toFixed(2) || "0").toString() : "0";
        const valorAjuste = (Number(percentualFlex) / 100) * integrationPrice;

        const integrationPriceFlex = Number(((integrationPrice + valorAjuste) * 100) / 100);
        const priceFlex = flexIntegrationPrice?.discountPrice?.[paymentCode] || 0;
        const totalPriceFlex = priceFlex * item.item.quantity;
        const unitPriceFlexPerItem = (priceFlex / qty).toFixed(2);

        const precoPauta = preco?.VLR_PAUTA || 0;
        const tributacao = preco ? HandlePrices.getTributacao(selectedClient, preco.COD_TRIBUTACAO_PRODUTO, listaTributacao) : undefined;

        const precoFlexST = HandlePrices.CalculaST(tributacao, integrationPriceFlex * item.item.quantity, precoPauta);

        const percentualFlexNumber = Number(percentualFlex);
        const hasFlex = percentualFlexNumber !== 0;

        return {
          cod_segmento,
          unilever: item?.product?.UNILEVER,
          sku: (item?.item?.sku).toString(),
          product: item?.product?.DESC_PRODUTO || "",
          price: preco?.VLR_FINAL ? ((preco?.VLR_FINAL * item?.item?.quantity)?.toFixed(2))?.toString() : "",
          unit_price: preco?.VLR_FINAL?.toString() || "",
          unit_price_per_item: preco?.VLR_FINAL ? ((preco.VLR_FINAL / qty)?.toFixed(2))?.toString() : "",
          st_price: preco?.ST_VLR_FINAL?.toString() || "",
          integration_price: integrationPrice,
          quantity: item?.item?.quantity || 0,
          packaging: item?.item?.option_uid || "",
          st_price_packaging: preco?.ST_VLR_FINAL || 0,
          unit_price_flex: hasFlex ? priceFlex.toFixed(2) : 0, //preco unitario com st
          integration_price_flex: hasFlex ? integrationPriceFlex.toFixed(2) : 0,
          unit_price_per_item_flex: hasFlex ? unitPriceFlexPerItem : 0,
          st_price_flex: hasFlex ? precoFlexST : 0, 
          price_flex: hasFlex ? totalPriceFlex.toFixed(2) : 0,
          percentual_flex: percentualFlexNumber !== 0 ? percentualFlex : "0",
          quantity_multiplo: preco?.QTD_MULTIPLO_VENDA || 1,
          status: "",
          reason: "",
        };
      });

      if (selectedClient && items && cartDetails) {
        return {
          company: {
            unilever: selectedClient.UNILEVER,
            name: selectedClient.NM_CLIENTE.toString(),
            id: selectedClient.COD_CLIENTE.toString(),
            cnpj: selectedClient.NR_DOCUMENTO.toString(),
            Cod_rca: selectedClient.COD_RCA.toString(),
          },
          orders: {
            order_number: generateOrderNumber(selectedClient?.COD_RCA),
            erp_order_number: erpOrderNumber ?? "",
            order_number_magento: "",
            origin: "BateForte_web_mobile_rca",
            items: cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.length,
            status: "",
            reason: "",
            order_date: format(now, "EEEE, dd/MM/yyyy", { locale: ptBR }),
            total: Number(cartTotals?.total?.toFixed(2)),
            payment_terms: paymentCode.toString(),
            actions_pending: items,
          },
        };
      }
    }

    return null;
  }, [productList, selectedClient, cartDetails, paymentCode, listaTributacao, getDiscountProduct, cartTotals?.total, erpOrderNumber]);
  
  const checkoutOrder = useCallback(async () => {
    if (!orderPayload) {
      console.log("Order payload is not ready");
      return;
    }

    if (connectivityStatus === "online") {
      try {
        setLoading(true);

        const synchedList = getOnStorage("synched_orders");
        let ordersSynched = [];

        const response = await axiosCtx
          .post("/integra-pedido", orderPayload)
          .then(res => res)
          .catch((error) => {
            console.log(`Error, ${error}`);
            // return error;
            throw error;
          }) as any;

        if (response?.data?.message) {
          if (synchedList && synchedList !== undefined && synchedList !== "undefined" && synchedList?.length) {
            ordersSynched = synchedList;
          }
          ordersSynched.push({
            ...orderPayload,
            orders:
            {
              ...orderPayload?.orders,
              status: "processando",
              erp_order_number: "",
              erp_order_number_client: erpOrderNumber ?? "",
            }
          });
          setOnStorage("synched_orders", ordersSynched);
          setLoading(false);
          setSucess(true);

          sessionStorage.setItem("orderData", JSON.stringify(orderPayload));
          sessionStorage.setItem("cartTotals", JSON.stringify(cartTotals));
          sessionStorage.setItem("orderItems", JSON.stringify(productList));

          clearClientDiscount();
          if (setFlex) setFlex((rcaSaldoFlex || 0) + discountAmountClient);


          handleControlStock();
          handleRemoveItems();
          setCanAccessSuccess(true);
          navigate(`/sucesso`);
        } else {
          setLoading(false);
          setSucess(false);
          window.alert('Um erro ocorreu ao fechar pedido. Tente novamente mais tarde.');
        }
      } catch (error) {
        setLoading(false);
        setSucess(false);
        console.error("Error sending order:", error);
        window.alert('Um erro ocorreu ao fechar pedido. Tente novamente mais tarde.');
      }
    } else {
      try {
        const addOrderToStorage = (orderPayload: OrderPayloadProps) => {
          let orders = getOnStorage("offline_orders") as any[];
          let offlineOrders = orders;

          if (!offlineOrders?.length || !offlineOrders) {
            offlineOrders = [];
            offlineOrders.push(orderPayload);
          } else {
            try {
              offlineOrders = [...offlineOrders, orderPayload];
            } catch (e) {
              console.error("Error parsing offline_orders from storage:", e);
              // offlineOrders = [];
            }
          }

          setOnStorage("offline_orders", offlineOrders);
        };
        addOrderToStorage(orderPayload);

        sessionStorage.setItem("orderData", JSON.stringify(orderPayload));
        sessionStorage.setItem("cartTotals", JSON.stringify(cartTotals));
        sessionStorage.setItem("orderItems", JSON.stringify(productList));

        setLoading(false);
        setSucess(true);

        handleControlStock();
        handleRemoveItems();
        setCanAccessSuccess(true);
        navigate(`/sucesso`);
      } catch (e) {
        setLoading(false);
        setSucess(false);
        console.log("Error sending order:", e);
      }
    }
  }, [orderPayload, connectivityStatus, axiosCtx, erpOrderNumber, cartTotals, productList, clearClientDiscount, setFlex, rcaSaldoFlex, discountAmountClient, handleControlStock, handleRemoveItems, setCanAccessSuccess, navigate]);

  const total = useMemo(() => {
    return cartTotals?.total;
  }, [cartTotals]);

  const creditLimitExceeded = useMemo(() => {
    return cartTotals?.total > creditLimit
  }, [cartTotals, creditLimit]);

  const disabled = useMemo(() => {
    if ((cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.length === 0) || loading || !selectedClient || creditLimitExceeded) {
      return true;
    }

    return false;
  }, [cartDetails, selectedClient, creditLimitExceeded, loading]);

  return {
    total,
    disabled,
    cartItems,
    conditions,
    productList,
    paymentCode,
    shippingData,
    checkoutOrder,
    setPaymentCode,
    creditLimitExceeded,
    erpNumber,
    setErpNumber,
  };
};
