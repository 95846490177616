import { colors } from "@/constants";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import {
  RiChat1Line,
  RiFundsLine,
  RiGroup2Line,
  RiInformation2Fill,
  RiExchangeDollarLine,
} from "react-icons/ri";

export const InformationIcon = styled(RiInformation2Fill)`
  cursor: pointer;
  margin-left: 5px;
  margin-top: -1px;
`;

export const Content = styled.div``;

export const BgDark = styled.div`
  background-color: ${colors.brand.grafite};
`;

export const PaddingContent = styled.div`
  padding: 0px 10px;
`;

export const Header = styled.div`
  width: 100%;
  padding-top: 14px;
  margin-bottom: 15px;
  padding-bottom: 20px;
  display: inline-flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.gray.gray60};
`;

export const Balance = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;

export const News = styled.div`
  width: 100%;
  margin-top: 36px;
  position: relative;
  display: inline-flex;
  padding: 24px 10px 22px 30px;
  justify-content: space-between;
  border-radius: 8px 8px 0px 0px;
  background-color: ${colors.alert.orange100};
`;

export const ChatLine = styled(RiChat1Line)`
  color: ${colors.brand.white};
`;

export const ExchangeDollarLine = styled(RiExchangeDollarLine)`
  color: ${colors.gray.gray40};
  margin-bottom: 7px;
`;

export const FundsLine = styled(RiFundsLine)`
  color: ${colors.gray.gray40};
  margin-bottom: 7px;
`;

export const GroupLine = styled(RiGroup2Line)`
  color: ${colors.gray.gray40};
  margin-bottom: 7px;
`;

export const BgFastLinks = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: column;
  width: -webkit-fill-available;
  padding: 0 20px;
  background-color: ${colors.grey7};
  text-decoration: none;

  svg {
    width: 24px;
    height: 24px;
    color: ${colors.gray.gray40};
  }

  &:nth-of-type(2) {
    border-left: 1px solid ${colors.background4};
    border-right: 1px solid ${colors.background4};
  }
`;

export const BgDivisor = styled.div`
  gap: 1px;
  width: 100%;
  align-items: stretch;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-evenly;
  background-color: ${colors.grey7};
`;

export const FastLinks = styled.div`
  /* overflow: hidden; */
  padding: 16px 0px;
  border-radius: 0px 0px 8px 8px;
  background-color: ${colors.grey7};
`;

export const DataInfos = styled.div`
  gap: 12px;
  align-items: center;
  display: inline-flex;
`;

export const Image = styled.div`
  width: 44px;
  height: 44px;
  display: grid;
  border-radius: 50%;
  place-items: center;
  background-color: ${colors.grey7};
`;

export const Sigla = styled.p`
  margin: 0px;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.gray.gray40};
`;

export const NameContent = styled.div``;

export const GoodDay = styled.p`
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  color: ${colors.brand.white};
`;

export const Name = styled.p`
  margin: 0px;
  font-size: 20px;
  font-weight: 600;
  color: ${colors.brand.white};
`;

export const Notifications = styled.div`
  position: relative;
`;

export const Alert = styled.div`
  top: 0px;
  width: 10px;
  height: 10px;
  right: -2px;
  border-radius: 50%;
  position: absolute;
  background-color: ${colors.brand.red};
`;

export const ContentBalance = styled.div``;

export const BalanceLabel = styled.div<{ direction?: string }>`
  margin: 0px;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 4px;
  color: ${colors.gray.gray40};
  text-align: ${({ direction }) => direction || "left"};
  span {
    font-weight: 800;
    margin-left: 4px;
  }
`;

export const BalanceLabelBold = styled.span``;

export const BalanceValueERP = styled.p`
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.brand.white};
`;

export const BalanceValue = styled.p`
  margin: 0px;
  font-size: 16px;
  font-weight: 400;
  color: ${colors.brand.white};
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  span {
    margin: 0px 3px;
  }
`;

export const BalanceValueRealce = styled.span`
  font-size: 25px;
  font-weight: 600;
`;

export const Icon = styled.div``;

export const AlertNews = styled.p`
  top: -0px;
  transform: translateY(-50%);
  margin: 0px;
  left: 20px;
  font-size: 12px;
  font-weight: 700;
  padding: 4px 10px 5px 10px;
  position: absolute;
  border-radius: 20px;
  color: ${colors.brand.white};
  background-color: ${colors.alert.orange300};
`;

export const FastLinkLabel = styled.span`
  margin: 0px;
  font-size: 11px;
  font-weight: 400;
  line-height: 14.3px;
  text-align: center;
  color: ${colors.brand.white};
`;

export const LeftColumnNews = styled.div`
  width: 175px;
`;

export const RightColumnNews = styled.div`
  position: absolute;
  right: 0;
  top: -32px;
  width: 170px;
  height: 170px;
`;

export const NewsTitle = styled.p`
  margin: 0px;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: ${colors.brand.grafite};
`;

export const NewsTitlwSpan = styled.span`
  color: ${colors.alert.orange300};
`;

export const NewsText = styled.p`
  margin: 8px 0px;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  color: ${colors.gray.gray50};
`;

export const NewsButton = styled.button`
  font-size: 12px;
  font-weight: 700;
  padding: 8px 16px;
  border-radius: 20px;
  background-color: transparent;
  border: 1px solid ${colors.black};
`;