import { useMemo } from 'react';
import * as S from './styles';
import { formatCurrency } from '@/shared';
import { ClientProps, PriceProductProps, TaxSubstitutionProps } from '@/types';
import { HandlePrices, calcPrice } from '@/shared/calculatePrices';
import { useCartTotals, useDiscountAmountFlex, useGlobals } from '@/hooks';

interface Props {
  precoSt?: number;
  precoCalculado?: number;
  quantidade?: number;
  cliente?: ClientProps;
  extraPadding?: boolean;
  color?: string;
  weight?: number;
  codTributacaoProduto?: number;
  precoPauta?: number;
  listaTributacao?: TaxSubstitutionProps[];
  descontoFlex?: number;
  productUid?: string;
  prices?: PriceProductProps[];
}

export const StPrice = ({
  precoSt,
  precoCalculado,
  quantidade = 1,
  cliente,
  extraPadding,
  color,
  weight,
  codTributacaoProduto,
  precoPauta = 0,
  descontoFlex,
  // productUid,
  // prices,
}: Props) => {

  const { listaTributacao } = useCartTotals();

  const price = useMemo(() => {
    if (descontoFlex) {
      if (cliente && codTributacaoProduto && precoCalculado && listaTributacao) {

        const precoCalc = precoCalculado + ((precoCalculado / 100) * descontoFlex);

        const tributacao = HandlePrices.getTributacao(cliente, codTributacaoProduto, listaTributacao);

        const preco = HandlePrices.CalculaST(tributacao, precoCalc * quantidade, precoPauta);

        return formatCurrency({ value: preco ? preco : precoSt ? precoSt : 0 });
      }
    }
    if (!quantidade || quantidade <= 1) {
      return formatCurrency({ value: precoSt ? precoSt : 0 });
    } else {
      if (cliente && codTributacaoProduto && precoCalculado && listaTributacao) {
        const tributacao = HandlePrices.getTributacao(cliente, codTributacaoProduto, listaTributacao);

        const preco = HandlePrices.CalculaST(tributacao, precoCalculado * quantidade, precoPauta);
  
        return formatCurrency({ value: preco ? preco : precoSt ? precoSt : 0 });
      }
    }
  }, [descontoFlex, quantidade, cliente, codTributacaoProduto, precoCalculado, listaTributacao, precoPauta, precoSt]);

  return (
    <S.Container $extraPadding={extraPadding}>
      <S.Text
        $weight={weight}
        $shouldShow={precoSt ? true : false}
      >
        {precoSt ? 'ST' : ''}
      </S.Text>
      {precoSt ? 
      <S.Text
        $size={10}
        $weight={400}
        $color={color || '#828282'}
      >
        {price}
      </S.Text> :
      ''}
    </S.Container>
  )
}

export default StPrice;
