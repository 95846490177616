import * as S from "./styles";
import { colors } from "@/constants";
import { ClientProps } from "@/types";
import { useMemo, useState } from "react";
import { formatCode, formatCurrency } from "@/shared";
import { useDiscountAmountFlex, useUserContext } from "@/hooks";
import { Accordion, ToolTip, ShortProgressBar } from "@/components";
import { calcularDiasAtras, formatarCEP, formatarData, formatarTelefone } from "@/shared/commonFunctions";
import { RiMapPin2Line, RiExchangeDollarLine, RiCalendarEventLine, RiDirectionLine, RiCalendarScheduleLine, RiFlag2Line } from "react-icons/ri";

const types = {
  1: 'default',
  2: 'success',
  3: 'warning',
  4: 'danger',
}
interface Props {
  isChecked?: boolean;
  showProgress?: boolean;
  client: ClientProps;
  setClient: (value: ClientProps | undefined) => void;
}

const ClientCard = ({ showProgress, isChecked, client, setClient }: Props) => {
  const { customer } = useUserContext()
  const [accordion, setAccordion] = useState(false);
  const { getDiscountAmountByClient } = useDiscountAmountFlex();

  const hasDiscount = useMemo(
    () => !!getDiscountAmountByClient(client.COD_CLIENTE),
    [client.COD_CLIENTE, getDiscountAmountByClient]
  );
  
  const { since, until, currentStatus, currentWeek } = useMemo(() => {
    const since = formatarData(Math.min(...client?.CYCLE?.map(c => c.DE) || []) || '', { year: '2-digit' });
    const until = formatarData(Math.max(...client?.CYCLE?.map(c => c.ATE) || []) || '', { year: '2-digit' });
    
    const current = client.CYCLE.find((cy) => cy.SEMANA_CICLO_ID_VIGENTE === cy.SEMANA_CICLO_ID);
    const currentWeek = current?.SEMANA_CICLO_ID_VIGENTE || 0
    const currentStatus = current?.STATUS_POSITIVACAO || 1

    return { since, until, currentStatus, currentWeek}
  }, [client.CYCLE]);
  
  const indexUltCompra = useMemo(() => {
    
    if (!client?.DT_ULTIMA_COMPRA.length) return 0
    
    const list = client.DT_ULTIMA_COMPRA //?.filter(dt => dt.nro_representante === rca?.codSegmento) || []
    
    if (customer?.codSegmento !== 999 && customer?.codSegmento !== 998) {
      return Math.max(list.findIndex(dt => dt.nro_segmento === customer?.codSegmento), 0);
    }

    const segments = customer?.codSegmento === 999 ? [60, 61] : [30, 33];
    
    const mapedList = list?.map(c => Number(`
      ${segments.includes(c.nro_segmento) ? 1 : 0}
      ${c.dta_ult_compra.replace('/', '')}
    `)) || []

    const max = Math.max(...mapedList)

    return mapedList.findIndex(dt => dt === max) || 0
  }, [client, customer?.codSegmento]);

  const dtUltimaCompra = useMemo(() => {
    if (!client?.DT_ULTIMA_COMPRA?.length) return ''

    return client.DT_ULTIMA_COMPRA[indexUltCompra]?.dta_ult_compra
  }, [indexUltCompra, client?.DT_ULTIMA_COMPRA])

  const datesMsg = useMemo(() => {
    if (!client?.DT_ULTIMA_COMPRA?.length) return ''

    const dates = client.DT_ULTIMA_COMPRA.splice(indexUltCompra, 1)
    return dates.map(date => `Data da ultima Compra no segmento ${date.nro_segmento}: ${formatarData(date.dta_ult_compra)}`).join(' | ')
  }, [indexUltCompra, client?.DT_ULTIMA_COMPRA])

  const isDisabled = client.ST_COMPRA === "I";
  const isDanger = client.daysWithoutPurchase >= 60;
  const isWarning = client.daysWithoutPurchase >= 30 && !isDanger;
  const hasAlert = isDanger || isWarning;
  const currentType = types[currentStatus] as S.CardColor;

  return (
    <S.Card themeColor={currentType} $ischecked={isChecked}>
      <S.ContentInfos $space={!accordion}>
        <S.InfosContent>
          <S.Row $between>
            <S.Column onClick={() => !isDisabled && setClient(isChecked ? undefined : client)}>
              <S.CompanieName data-test={`card-company-title-${client.COD_CLIENTE}`}>
                <S.NumberCompanie themeColor={currentType} $isActive={isChecked}>
                  {client.COD_CLIENTE}
                </S.NumberCompanie>

                <S.FantasyName $isdisabled={isDisabled}>
                  {client.NM_CLIENTE}
                </S.FantasyName>
              </S.CompanieName>

              <S.CNPJLabel $isdisabled={isDisabled}>
                {formatCode(client.NR_DOCUMENTO)}
              </S.CNPJLabel>
            </S.Column>
            
            <S.ColumnRight>
              <S.Row>
                {hasDiscount && <RiExchangeDollarLine color={colors.alert.green300} fontSize={24} />}

                {isDisabled ? (
                  <S.ContentProhibited>
                    <S.ProhibitedIcon size={16} />
                  </S.ContentProhibited>
                ) : (
                  <S.CheckContent
                    onClick={() => !isDisabled && setClient(isChecked ? undefined : client)}
                    data-test={`card-company-check-select-${client.COD_CLIENTE}`}
                  >
                    <S.CheboxContent $isdisabled={isDisabled}>
                      {isChecked ? (
                        <S.CheckboxCircle size={24} />
                      ) : (
                        <S.CheckboxBlankCircle size={24} />
                      )}
                    </S.CheboxContent>
                  </S.CheckContent>
                )}
              </S.Row>

              <S.Row marginTop={4}>
                <ToolTip text="Em breve" displayMode="hover">
                  {!client.notifications && (
                    <S.ContentBar className="disabled">
                      <S.BarIcon size={24} />

                      <S.BarNotification>
                        {client.notifications}2
                      </S.BarNotification>
                    </S.ContentBar>
                  )}
                </ToolTip>

                <ToolTip text="Em breve" displayMode="hover">
                  <RiDirectionLine className="disabled" fontSize={24} color={colors.gray.gray50} />
                </ToolTip>
              </S.Row>
            </S.ColumnRight>
          </S.Row>

          {dtUltimaCompra &&
            <ToolTip text={datesMsg} displayMode="hover">
              <S.LastPurchaseContent $isdisabled={isDisabled} themeColor={currentType}>
                {currentType === 'warning' && <RiFlag2Line color={colors.gray.gray50} fontSize={18} />}
                {currentType === 'default' && <RiCalendarEventLine color={colors.gray.gray50} fontSize={18} />}
                {currentType === 'success' && <RiCalendarEventLine color={colors.gray.gray50} fontSize={18} />}
                {currentType === 'danger' && <RiCalendarScheduleLine color={colors.gray.gray50} fontSize={18} />}

                <S.LastPurchase>Última compra em {formatarData(dtUltimaCompra)} - {calcularDiasAtras(dtUltimaCompra)} dias</S.LastPurchase>
              </S.LastPurchaseContent>
            </ToolTip>
          }

          <S.AddresContent $isdisabled={isDisabled} onClick={() => !isDisabled && setClient(isChecked ? undefined : client)}>
            <S.MapContent themeColor={currentType}>
              <RiMapPin2Line fontSize={18} />
            </S.MapContent>

            <S.Address>
              {`${client.ENDERECO}`}, {`${client.NUMERO}`} - {`${client.BAIRRO}`}, {`${client.CIDADE}`} - {`${client.ESTADO}`}

              <S.Row $between>
                <S.CEP>
                  {formatarCEP(`${client.CEP}`)}
                </S.CEP>

                <S.Phone>
                  {formatarTelefone(client.NR_TELEFONE)}
                </S.Phone>
              </S.Row>
            </S.Address>
          </S.AddresContent>

          <S.CycleContent $isdisabled={isDisabled}>
            <S.Cycle>
              <S.Label marginBottom={12} weight={500}>Ciclo</S.Label>
              <S.SubLabel>{since} a {until}</S.SubLabel>

              {!showProgress && <S.Row marginTop={4} $between>
                <S.ProgressContent>
                  {client.CYCLE.map((cycle) => {
                    return <ShortProgressBar isCurrent={cycle.SEMANA_CICLO_ID_VIGENTE === currentWeek} state={cycle.POSITIVOU ? "checked" : 'empty'} />
                  })}
                </S.ProgressContent>
              </S.Row>}
            </S.Cycle>

            <S.Separator />

            <S.Positivacao>
              <ToolTip text="Em breve" displayMode="hover">
                <S.CardTitle className="disabled">Positivação de clientes</S.CardTitle>

                <S.Row className="disabled" $between>
                  <S.SubLabel>Atingido</S.SubLabel>
                  <S.SubLabel>Pendente</S.SubLabel>
                </S.Row>

                <S.Row className="disabled" $between>
                  <S.Label>R$ ----</S.Label>
                  <S.Label>R$ ----</S.Label>
                  {/* <S.Label>{formatCurrency({ value: 9830 })}</S.Label>
                <S.Label>{formatCurrency({ value: 2830 })}</S.Label> */}
                </S.Row>
              </ToolTip>
            </S.Positivacao>
          </S.CycleContent>
        </S.InfosContent>
      </S.ContentInfos>

      <S.ContentAlert themeColor={currentType} $showBorder={hasAlert || accordion}>
        {hasAlert && (
          <S.AlertMessage type={isDanger ? "danger" : "warning"}>
            {isWarning && <S.FlagIcon size={15} />}
            {isDanger && <S.CalendarIcon size={15} />}
            Não compra há mais de
            <S.AlertMessageBold>
              {client.daysWithoutPurchase} dias
            </S.AlertMessageBold>
          </S.AlertMessage>
        )}

        <Accordion
          noRadius
          noBodyPadding
          accordionTitle=""
          showHeader={false}
          bgColor={colors.transparent}
          accordionExpanded={accordion}
          setAccordionExpanded={setAccordion}
          id={`company-${client.COD_CLIENTE}`}
        >
          <S.AccordionContent>
            <S.AccordionInfos themeColor={currentType}>
              <S.AccordionDataInfo>
                <S.AccordionLabel>Limite de crédito:</S.AccordionLabel>
                <S.AccordionLimit>
                  {formatCurrency({ value: client.LIMITE_CREDITO })}
                </S.AccordionLimit>
              </S.AccordionDataInfo>

              <S.AccordionDataInfo>
                <S.AccordionLabel>Saldo disponível:</S.AccordionLabel>
                <S.AccordionLimit>
                  {formatCurrency({ value: client?.SALDO_DISPONIVEL || 0 })}
                </S.AccordionLimit>
              </S.AccordionDataInfo>
            </S.AccordionInfos>

            <ToolTip text="Em breve" displayMode="click">
              <S.RequestLimit >
                <S.IconPlus size={18} />
                Solicitar mais limite de crédito
              </S.RequestLimit>
            </ToolTip>
          </S.AccordionContent>
        </Accordion>

        <S.ArrowContent
          $isOpened={accordion}
          $isdisabled={isDisabled}
          onClick={() => !isDisabled && setAccordion(!accordion)}
        >
          <S.OpenDetails>
            {!accordion ? 'Mais' : 'Menos'} detalhes
          </S.OpenDetails>

          <S.ArrowIcon
            size={15}
            $isOpened={accordion}
            data-test={`card-company-handle-accordion-${client.COD_CLIENTE}`}
          />
        </S.ArrowContent>
      </S.ContentAlert>
    </S.Card>
  );
};

export default ClientCard;
