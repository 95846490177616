import { colors } from "@/constants";
import { ClientProgress } from "@/types";
import styled, { css } from "styled-components";

import {
  RiProhibitedLine,
  RiCheckboxCircleFill,
  RiCheckboxCircleLine,
  RiCheckboxBlankCircleLine,
  RiIndeterminateCircleLine,
} from "react-icons/ri";

const iconStyle = css`
  top: 0px;
  border: 2px;
  right: -2px;
  border-radius: 50%;
  position: absolute;
  transform: translateY(calc(-50% + 4px));
`;

export const IndeterminateCircleLine = styled(RiIndeterminateCircleLine)`
  ${iconStyle}
  color: ${colors.alert.orange300};
  background-color: ${colors.alert.orange70};
`;

export const CheckboxCircleLine = styled(RiCheckboxCircleLine)`
  ${iconStyle}
  color: ${colors.gray.gray30};
  background-color: ${colors.gray.gray10};
`;

export const ProhibitedLine = styled(RiProhibitedLine)`
  ${iconStyle}
  color: ${colors.alert.red200};
  background-color: ${colors.alert.red100};
`;

export const CheckboxCircle = styled(RiCheckboxCircleFill)`
  ${iconStyle}
  color: ${colors.alert.green300};
  background-color: ${colors.gray.gray10};
`;

export const CheckboxBlankCircle = styled(RiCheckboxBlankCircleLine)`
  color: ${colors.gray.gray40};
`;

export type Props = {
  isCurrent?: boolean;
  state: ClientProgress;
};

export type Types = {
  [key in ClientProgress]?: any;
};

const styles: Types = {
  danger: { bg: colors.alert.red50, border: colors.alert.red100 },
  warning: { bg: colors.alert.orange100, border: colors.gray.gray10 },
  checked: { bg: colors.alert.green300, border: colors.gray.gray20 },
  "semi-checked": { bg: colors.gray.gray10, border: colors.gray.gray20 },
  empty: { bg: colors.gray.gray10, border: colors.gray.gray20 },
};

export const Current = styled.div`
  width: 3px;
  height: 20px;
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translateY(-50%);
  background-color: ${colors.brand.red};
`

export const Progress = styled.div<Props>`
  width: 100%;
  height: 10px;
  position: relative;
  border-radius: 6px;
  background-color: ${(i) => styles[i.state].bg};
  border: 1px solid ${(i) => styles[i.state].border};
`;

export const BarProgress = styled.div<{ $isFill?: boolean }>`
  height: 100%;
  border-radius: 6px;
  width: ${(i) => (i.$isFill ? 100 : 50)}%;
  background-color: ${colors.alert.green300};
`;
