import { useState, useMemo } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR } from 'date-fns/locale';

import { useNetworkStatusContext } from "@/hooks";
import { useMyOrdersPage } from "@/talons/myOrders/useMyOrdersPage";
import { OrderCard } from "./order-card/orderCard";
import EmptyOrderPage from "./emptyPage/EmptyPage";
import OfflinePage from "./offlinePage/OfflinePage";

import { Header, Loading, Paginator } from "@/components";

import { RiUserStarLine, RiUserLine } from "react-icons/ri";

import * as S from "./styles";

interface FilterProps {
    filterActive: 'RCA' | 'Cliente';
}

const MyOrders = () => {
    const { selectedClient, rcaOrders, ecommerceOrders, loading, totalPages, currentPage, handlePagination, orderDates, dateFilter, setDateFilter } = useMyOrdersPage();
    const { connectivityStatus } = useNetworkStatusContext();
    const [filterActive, setFilterActive] = useState<FilterProps['filterActive']>('RCA');

    const [filter, setFilter] = useState("");
    const [showDateFilter, setShowDateFilter] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);

    const mostRecentDate = useMemo(() => {
        if (orderDates && orderDates.length > 0) {
            return new Date(Math.max(...orderDates.map((date: any) => date.getTime())));
        }
        return new Date(); // Retorna a data atual se `orderDates` estiver vazio
    }, [orderDates]);

    const orders = useMemo(() => filterActive === 'RCA' ? rcaOrders : ecommerceOrders, [filterActive, rcaOrders, ecommerceOrders]);

    const renderOrders = useMemo(() => {
        return orders.reduce((acc: any[], order: any) => {
            const orderDate = new Date(order.order_date).toISOString().split('T')[0];
            const selectedDate = dateFilter ? dateFilter.toISOString().split('T')[0] : null;
            const isMatchingDate = !selectedDate || orderDate === selectedDate;
            const isMatchingNumber = !filter || order.number.includes(filter);

            if (isMatchingDate && isMatchingNumber) {
                acc.push(
                    <OrderCard
                        key={order.id}
                        order={order}
                    />
                );
            }
            return acc;
        }, []);
    }, [filterActive, filter, dateFilter, orders]);

    if (connectivityStatus === 'offline') {
        return <OfflinePage />
    }

    if (((!rcaOrders || rcaOrders.length === 0) && (!ecommerceOrders || ecommerceOrders.length === 0)) && !loading) {
        return <EmptyOrderPage />
    }

    const handleClearDate = () => {
        setDateFilter(null); // Desmarca a data
    };

    return (
        <>
            {!openSearch ? (
                <Header
                    rightIcon={
                        <S.SearchContent>
                            <S.SearchIcon
                                size={20}
                                data-test="my-clients-button-search"
                                onClick={() => setOpenSearch(true)}
                            />
                        </S.SearchContent>
                    }
                />
            ) : (
                <S.SearchHeader>
                    <S.SearchInputWrapper>
                        <S.SearchIcon
                            size={20}
                            data-test="my-clients-input-search"
                            $isgray
                        />
                        <S.SearchInput
                            type="text"
                            placeholder="Busque por número do pedido..."
                            onChange={(event: any) => setFilter(event.target.value.trim())}
                        />
                    </S.SearchInputWrapper>
                    <S.CloseSearch onClick={() => setOpenSearch(false)}>
                        Cancelar
                    </S.CloseSearch>
                </S.SearchHeader>
            )}
            <S.Container>
                <S.FilterBox>
                    <S.Filter>
                        <S.FilterOption
                            id="RCA"
                            isActive={filterActive === 'RCA'}
                            onClick={() => setFilterActive('RCA')}
                        >
                            <RiUserStarLine />
                            <S.FilterLabel>Representante Comercial</S.FilterLabel>
                        </S.FilterOption>
                        <S.FilterOption
                            id="Cliente"
                            isActive={filterActive === 'Cliente'}
                            onClick={() => setFilterActive('Cliente')}
                        >
                            <RiUserLine />
                            <S.FilterLabel> {selectedClient?.NM_FANTASIA} </S.FilterLabel>
                        </S.FilterOption>
                    </S.Filter>
                </S.FilterBox>
                {
                    ((filterActive === 'RCA' && rcaOrders.length === 0) ||
                        (filterActive === 'Cliente' && ecommerceOrders.length === 0)) && !loading ? (
                        <EmptyOrderPage />
                    ) : (
                        <>
                            {!loading && (
                                <>
                                    <S.FilterHeader>
                                        <S.FilterButton onClick={() => setShowDateFilter(!showDateFilter)}>
                                            <S.FilterIcon />
                                            Filtrar
                                        </S.FilterButton>
                                        {dateFilter && (
                                            <S.ClearFilter onClick={() => handleClearDate()}>
                                                Remover filtro
                                            </S.ClearFilter>
                                        )}

                                    </S.FilterHeader>

                                    <S.CollapsibleFilterBox isVisible={showDateFilter}>
                                        <DatePicker
                                            selected={dateFilter}
                                            onChange={(date: any) => setDateFilter(date)}
                                            highlightDates={orderDates}
                                            openToDate={mostRecentDate}
                                            inline
                                            placeholderText="Selecione uma data"
                                            isClearable
                                            showYearDropdown
                                            locale={ptBR}
                                        />
                                    </S.CollapsibleFilterBox>

                                </>
                            )}

                            <S.OrdersContainer>
                                {loading ? (
                                    <S.LoaderContainer>
                                        <Loading scale={2} />
                                    </S.LoaderContainer>
                                ) : renderOrders}
                            </S.OrdersContainer>
                        </>
                    )
                }
                {!loading && totalPages > 1 && ((filterActive == 'RCA' && rcaOrders.length > 0) || (filterActive === 'Cliente' && ecommerceOrders.length > 0)) && (
                    <S.PageControl>
                        <Paginator totalPages={totalPages} currentPage={currentPage} handlePagination={handlePagination} />
                    </S.PageControl>
                )}
            </S.Container>
        </>
    )
}

export default MyOrders;