import { colors } from "@/constants";
import styled,  { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";
import { RiLogoutBoxLine, RiArrowRightSLine } from "react-icons/ri";

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideUp = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

export const MenuList = styled.div`
  gap: 1px;
  display: flex;
  padding: 1px 0px;
  margin-bottom: 14px;
  flex-direction: column;
  background-color: ${colors.grey11};
`;

export const MenuItem = styled(NavLink) <{ $isDisabled?: boolean }>`
  border: none;
  padding: 10px;
  align-items: center;
  display: inline-flex;
  text-decoration: none;
  justify-content: space-between;
  background-color: ${colors.gray.gray10};
  opacity: ${(i) => (i.$isDisabled ? 0.3 : 1)};
`;

export const Submenu = styled.div<{ isOpen: boolean }>`
  list-style: none;
  display: flex;
  flex-direction: column;
  animation: ${(props) => (props.isOpen ? slideDown : slideUp)} 0.3s ease-out;
  max-height: ${(props) => (props.isOpen ? "200px" : "0")}; // ajusta a altura conforme necessário
  overflow: hidden;
 
  background-color: ${colors.grey11}; 
`;

export const IndicadoresMenuItems = styled.div`
  border: none;
  padding: 10px;
  align-items: center;
  display: flex;
  text-decoration: none;
  justify-content: space-between;
  background-color: ${colors.gray.gray10};
`;

export const ItemLabel = styled.p<{ isred?: string }>`
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  text-align: left;
  color: ${({ isred }) => (isred ? colors.alert.red200 : colors.gray.gray50)};
`;

export const ContentLink = styled.div<{ isred?: string }>`
  gap: 10px;
  align-items: center;
  display: inline-flex;
  svg {
    color: ${({ isred }) => (isred ? colors.alert.red200 : colors.gray.gray50)};
  }
`;

export const Arrow = styled(RiArrowRightSLine)`
  color: ${colors.grey8};
`;

export const Logout = styled(RiLogoutBoxLine)``;
