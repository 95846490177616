import { colors } from "../../constants";
import styled from "styled-components";

export const Title = styled.div<{ disabled?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  text-align: left;

  padding: 16px 10px;
  color: ${({ disabled }) => disabled ? colors.gray.gray30 : colors.gray.gray60};
  text-transform: uppercase;

  transition: all 0.4s;

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  border-bottom: 1px solid ${colors.grey11};

  svg{
    width: 20px;
    height: 20px;
    color: #09121F;
    color: ${({ disabled }) => disabled ? colors.gray.gray30 : colors.gray.gray60};
  }

`;

export const Container = styled.div`
  min-height: 100vh;
  overflow-y: scroll;
  padding-bottom: 90px;
  background: ${colors.gray.gray10};
`;

export const Tooltip = styled.div<{ isVisible: boolean }>`
  padding: 8px;
  background-color: ${colors.brand.grafite};
  border-radius: 6px;
  color: white;
  position: absolute;
  top: 110%;
  left: 50%;  
  transform: translateX(-50%) translateY(${({ isVisible }) => (isVisible ? '0' : '10px')});
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  z-index: 1000;
  text-transform: none;
  font-size: 12px;
  text-align: center;

  transition: opacity 0.4s, transform 0.4s;

  &::before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 6px;
    border-color:  transparent  transparent ${colors.brand.grafite} transparent;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
  }
`;