import { useClient } from "@/hooks";
import { ENCRYPT } from "@/constants";
import { openDB as idb, IDBPDatabase } from "idb";

import {
  BrandProps,
  StockProps,
  ClientProps,
  ProductProps,
  CategoryProps,
  AWSParamsProps,
  IndicatorsProps,
  PageBuilderProps,
  PromocaoClientProps,
  TaxSubstitutionProps,
  ParametroPrecoBaseProps,
  RecomendacaoBigDataProps,
  PercentualFornecedorProps,
  productRestrictionClientProps,
  SalesCycleProps,
  IndicatorsFaixaProps,
  TopMixProps,
  CampanhaProps,
} from "@/types";

const tableKeys = [
  "marcas",
  "indicadores",
  "campanha",
  "campanha_faixa",
  "campanha_mix",
  "produtos",
  "clientes",
  "categorias",
  "pagebuilder",
  "parametrosAws",
  "campanhaCliente",
  "campanhaProduto",
  "substituicaotributaria",
  "dicionario_categorias",
  "dicionario_produtos",
  "precoPromocaoCliente",
  "recomendacaoBigData",
  "cicloVendaCliente",
  "precos",
  "imagens",
  "estoque",
  "catalogo",
  "janelaEntrega",
  "parametroPrecoBase",
  "percentualFornecedor",
  "pedidos_offline",
  "productRestrictionClient",
  "saldoFlex",
] as const;

export type Tables = (typeof tableKeys)[number];
const listTables: Tables[] = [...tableKeys];

type TableTypes = {
  precos: any;
  imagens: any;
  catalogo: any;
  saldoFlex: any;
  janelaEntrega: any;
  pedidos_offline: any;
  marcas: BrandProps[];
  estoque: StockProps[];
  clientes: ClientProps[];
  produtos: ProductProps[];
  categorias: CategoryProps[];
  campanha: IndicatorsProps[];
  campanha_faixa: IndicatorsFaixaProps[];
  campanha_mix: TopMixProps[];
  campanhaCliente: CampanhaProps;
  campanhaProduto: CampanhaProps;
  indicadores: IndicatorsProps[];
  parametrosAws: AWSParamsProps[];
  pagebuilder: PageBuilderProps[];
  cicloVendaCliente: SalesCycleProps[];
  parametroPrecoBase: ParametroPrecoBaseProps[];
  substituicaotributaria: TaxSubstitutionProps[];
  recomendacaoBigData: RecomendacaoBigDataProps[];
  percentualFornecedor: PercentualFornecedorProps[];
  dicionario_produtos: Record<number, ProductProps>;
  dicionario_categorias: Record<number, CategoryProps>;
  productRestrictionClient: productRestrictionClientProps[];
  /**
    * @description Preço Promocao. [idCliend][idProduto]
  */
  precoPromocaoCliente: Record<number, Record<number, PromocaoClientProps>>;
};

export const toCoded = (str: string, forceEncrypt?: boolean, noReplace?: boolean) => {
  if (!str || (!ENCRYPT && !forceEncrypt)) return str
  const filteredStr = noReplace ? str : str.replace(/[^a-zA-Z]/g, '');

  try {
    const encoder = new TextEncoder();
    const uint8Array = encoder.encode(filteredStr);
    const binaryStr = String.fromCharCode(...uint8Array);
    const base64Str = window.btoa(binaryStr);

    return base64Str.replace(/[^a-zA-Z]/g, '');
  } catch (error) {
    return filteredStr;
  }
}

const DB_NAME = toCoded("BATE_FORTE");

export const openDBConnection = async () => {
  const currDB = await idb(DB_NAME, 1, {
    upgrade(db) {
      for (const name of listTables) {
        db.createObjectStore(toCoded(name), {
          keyPath: toCoded(name),
          autoIncrement: true,
        });
      }
    },
  });
  return currDB;
};

const transformBlobToJSON = async (item: any) => {
  if (!item || !ENCRYPT) return item;

  return new Promise((res) => {
    try {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        res(JSON.parse(event?.target?.result || {}));
      };
      reader.readAsText(item);
    } catch (error) {
      res(item)
    }
  });
};

const handleGet = async <T extends Tables>(
  name: T,
  currDB: IDBPDatabase<unknown>
): Promise<TableTypes[T]> => {
  const transaction = currDB?.transaction(toCoded(name), "readonly");
  const store = transaction?.objectStore(toCoded(name));
  const data = await store.getAll();
  const lastData = data?.pop() || []
  return transformBlobToJSON(lastData.data) as TableTypes[T];
};

export const useDbFunctions = () => {
  const { selectedClient } = useClient();

  const handleProductList = async <T extends Tables>(
    name: T,
    currDB: IDBPDatabase<unknown>
  ): Promise<TableTypes[T]> => {
    const list = (await handleGet("produtos", currDB)) || [];
    const restrictedList = (await handleGet("productRestrictionClient", currDB)) || [];

    const restrictions =
      restrictedList
        ?.filter((r: any) => r.COD_CLIENTE === selectedClient.COD_CLIENTE)
        ?.map((r: any) => r.COD_PRODUTO) || [];

    return list?.filter(
      (p: any) => !restrictions.includes(p.COD_PRODUTO)
    ) as TableTypes[T];
  };

  const closeDBConnection = (db: IDBPDatabase<unknown> | undefined) => {
    db?.close();
    db = undefined;
  };

  const getOnDB = async <T extends Tables>(name: T): Promise<TableTypes[T]> => {
    const currDB = await openDBConnection();

    const list = await (name === "produtos"
      ? handleProductList(name, currDB)
      : handleGet(name, currDB));

    closeDBConnection(currDB);
    return list as TableTypes[T];
  };

  const setOnDB = async (data: any[] | any, name: Tables) => {
    const currDB = await openDBConnection();
    const transaction = currDB?.transaction(toCoded(name), "readwrite");
    const store = transaction?.objectStore(toCoded(name));

    const bytes = new TextEncoder().encode(JSON.stringify(data));
    const blob = new Blob([bytes], { type: "application/json;charset=utf-8" });
    const allKeys = await store.getAllKeys();

    if (allKeys.length) {
      const key: any = allKeys[allKeys.length - 1]
      await store.delete(key);
    }
    
    await store.put({ data: ENCRYPT ? blob : data, keyPath: toCoded(name) });

    transaction.commit();
    closeDBConnection(currDB);
  };

  const deleteDB = async () => {
    indexedDB.deleteDatabase(DB_NAME);
    await new Promise((resolve) => setTimeout(() => resolve(true), 500));
  };

  return { getOnDB, setOnDB, deleteDB, closeDBConnection };
};
